<ngx-skeleton-loader
  [count]="1"
  [theme]="{
    'border-radius': '12px',
    width: '400px',
    height: '5px',
    'background-color': themeColor,
    'margin-right': '20px',
    'animation-duration': '2s',
    padding: '15px'
  }"
></ngx-skeleton-loader>
<div class="grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2 md:grid-cols-3">
  <article *ngFor="let x of cardList">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'border-radius': '12px',
        height: '350px',
        'background-color': themeColor,
        'margin-right': '20px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    >
    </ngx-skeleton-loader>
  </article>
</div>
