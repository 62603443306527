import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  headerHide: boolean = false;
  constructor(private route: Router) {
    this.route.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let urlSegments = event.url.split('/');
        // console.log(urlSegments);
        if (urlSegments[1] == 'payment-checkout') {
          this.headerHide = true;
        } else {
          this.headerHide = false;
        }
      });
    localStorage.setItem('theme-preference', 'light');
  }
  ngOnInit(): void {}
  title = 'shopwebsite';
}
