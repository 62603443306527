<footer class="dark:bg-jacarta-900 page-footer bg-white">
  <div class="px-6 xl:px-24">
    <!-- Footer -->
    <div class="grid grid-cols-1 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
      <!-- Logo, Description and Social Media icons -->
      <div class="col-span-3 md:col-span-4">
        <!-- Footer Logo -->
        <a href="index.html" class="mb-6 inline-block">
          <img
            [src]="footerData?.footer_logo_dark"
            class="max-h-21 dark:hidden"
            alt="ShopForAR Logo"
          />
          <img
            [src]="footerData?.footer_logo_light"
            class="hidden max-h-21 dark:block"
            alt="ShopForAR Logo"
          />
        </a>
        <!-- Footer Note -->
        <p
          class="dark:text-jacarta-300 mb-12"
          [innerHTML]="footerData?.footer_description"
        >
          <!-- "Shop for AR is a product of Immersion Software labs Pvt. Ltd. First
          of a kind lowcost 3DModels retail platform where all the products are
          AR Enabled." -->
        </p>
        <!-- Socials -->
        <div class="flex space-x-5">
          <!-- Facebook -->
          <a
            href="https://www.facebook.com/immersionslabs/"
            target="_blank"
            class="group"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook"
              class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
              ></path>
            </svg>
          </a>
          <!-- Twitter -->
          <a
            href="https://twitter.com/immersionslabs"
            target="_blank"
            class="group"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="twitter"
              class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              ></path>
            </svg>
          </a>
          <!-- Linkedin -->
          <a
            href="https://www.linkedin.com/company/immersionslabs/"
            target="_blank"
            class="group"
          >
            <svg
              _ngcontent-rqm-c53=""
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              data-icon="linkedin"
              viewBox="0 0 16 16"
              role="img"
              class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
            >
              <path
                _ngcontent-rqm-c53=""
                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 
              1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 
              0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 
              0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 
              1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 
              0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
              ></path>
            </svg>
          </a>
          <!-- Instagram -->
          <a
            href="https://www.instagram.com/immersionslabs/"
            target="_blank"
            class="group"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="instagram"
              class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <!-- India Address -->

      <div
        class="col-span-full sm:col-span-3 md:col-span-3"
        *ngFor="let addresses of footerData?.footer_addresses"
      >
        <h2 class="font-display text-jacarta-700 mb-8 text-xl dark:text-white">
          {{ addresses.Place }}
        </h2>
        <div class="mb-6 flex items-center space-x-5">
          <span
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-jacarta-400"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"
              />
            </svg>
          </span>
          <div>
            <span
              class="font-display text-jacarta-700 block text-base dark:text-white"
            >
              Phone
            </span>
            <a
              href="tel:{{ addresses.Phone }}"
              class="hover:text-accent dark:text-jacarta-300 text-sm"
            >
              {{ addresses.Phone }}
            </a>
          </div>
        </div>
        <div class="mb-6 flex items-center space-x-5">
          <span
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-jacarta-400"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
              />
            </svg>
          </span>
          <div>
            <span
              class="font-display text-jacarta-700 block text-base dark:text-white"
            >
              Address
            </span>
            <address class="dark:text-jacarta-300 text-sm not-italic">
              <!-- #710, 14th Cross, 1st Stage,<br />
              Chandra Layout, Bengaluru<br />
              Karnataka, INDIA 560072. -->
              {{ addresses.Address }}
            </address>
          </div>
        </div>
      </div>

      <!-- USA Address -->
      <!-- <div class="col-span-full sm:col-span-3 md:col-span-3">
        <h2 class="font-display text-jacarta-700 mb-8 text-xl dark:text-white">
          USA
        </h2>
        <div class="mb-6 flex items-center space-x-5">
          <span
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-jacarta-400"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"
              />
            </svg>
          </span>
          <div>
            <span
              class="font-display text-jacarta-700 block text-base dark:text-white"
            >
              Phone
            </span>
            <a
              href="tel:+15073513644"
              class="hover:text-accent dark:text-jacarta-300 text-sm"
            >
              (+1) 507 351 3644
            </a>
          </div>
        </div>
        <div class="mb-6 flex items-center space-x-5">
          <span
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 bg-light-base flex h-11 w-11 shrink-0 items-center justify-center rounded-full border"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-jacarta-400"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
              />
            </svg>
          </span>
          <div>
            <span
              class="font-display text-jacarta-700 block text-base dark:text-white"
            >
              Address
            </span>
            <address class="dark:text-jacarta-300 text-sm not-italic">
              14240 Sugar Hill Dr Little Elm,<br />
              Texas 75068,<br />
              UNITED STATES 75068.
            </address>
          </div>
        </div>
      </div> -->
      <!-- Apps -->
      <!-- <div class="col-span-full sm:col-span-2 md:col-span-2">
        <h3 class="font-display text-jacarta-700 mb-8 text-sm dark:text-white">
          Find Our App&apos;s On
        </h3>
        <ul class="dark:text-jacarta-300 flex flex-col space-y-1 bulletsNone">
          <li>
            <a href="#" class="hover:text-accent dark:hover:text-white">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.immersionslabs.lmeasurear"
              >
                <img
                  src="assets/img/google-play.webp"
                  alt="google_play"
                  class="imgres"
                />
              </a>
            </a>
          </li>
        </ul>
      </div> -->
    </div>
    <!-- Footer Disclaimer -->
    <div
      class="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0"
    >
      <span class="dark:text-jacarta-400 text-sm">
        <!-- &copy;
        {{ year }}
        <a
          href="https://www.immersionslabs.com/home"
          target="_blank"
          class="hover:text-accent dark:hover:text-white"
          >Immersion Software Labs Pvt. Ltd.</a
        > -->
        {{ footerData?.footer_desclaimer }}
      </span>
      <ul class="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
        <li>
          <a href="#" class="hover:text-accent dark:hover:text-white"
            >Terms and conditions</a
          >
        </li>
        <li>
          <a href="#" class="hover:text-accent dark:hover:text-white"
            >Privacy policy</a
          >
        </li>
      </ul>
    </div>
  </div>
</footer>
