<ng-container *ngIf="saleRendered === false; else saleDetailContainer">
  <!-- <app-loader></app-loader> -->
  <app-sale-detail-skelton></app-sale-detail-skelton>
</ng-container>
<ng-template #saleDetailContainer>
  <section class="relative pt-24 lg:pb-96">
    <div class="px-6 xl:px-24">
      <!-- Page Title -->
      <div class="mx-auto max-w-2xl py-16 text-center">
        <h1
          class="font-display text-jacarta-700 mb-8 text-4xl font-medium dark:text-white"
        >
          {{ saleItem?.sale_title }}
        </h1>
        <p
          class="dark:text-jacarta-300 text-lg leading-normal"
          [innerHTML]="saleItem?.sale_sub_description"
        ></p>
      </div>
    </div>
  </section>

  <section class="pb-12">
    <div class="px-6 xl:px-24">
      <!-- Video Lightbox -->
      <figure
        class="before:bg-jacarta-900/25 relative mt-16 overflow-hidden rounded-3xl before:absolute before:inset-0 lg:-mt-96"
      >
        <img
          [src]="cloudFrontURL + saleItem?.sale_image_one"
          class="w-full h-full"
          [alt]="saleItem?.sale_title"
        />
      </figure>

      <!-- Statistics -->
      <div class="pt-24">
        <p
          class="dark:text-jacarta-300 text-lg leading-normal"
          [innerHTML]="saleItem?.sale_description"
        ></p>

        <div class="grid grid-cols-1 md:grid-cols-4 py-16">
          <div class="mb-10 text-center">
            <span class="dark:text-jacarta-300 block">Discount</span>
            <span
              class="font-display text-jacarta-700 block text-5xl dark:text-white"
              >{{ saleItem?.sale_discount }} %</span
            >
          </div>
          <div class="mb-10 text-center">
            <span class="dark:text-jacarta-300 block">Start Date</span>
            <span
              class="font-display text-jacarta-700 block text-5xl dark:text-white"
              >{{ saleItem?.sale_start_date | date : "dd-MM-yyyy" }}</span
            >
          </div>
          <div class="mb-10 text-center">
            <span class="dark:text-jacarta-300 block">End Date</span>
            <span
              class="font-display text-jacarta-700 block text-5xl dark:text-white"
              >{{ saleItem?.sale_end_date | date : "dd-MM-yyyy" }}</span
            >
          </div>
          <div class="mb-10 text-center">
            <span class="dark:text-jacarta-300 block">Sale Status</span>
            <span class="font-display block text-5xl text-green">Active</span>
            <span
              *ngIf="saleItem?.sale_state == 0"
              class="font-display text-red block text-5xl"
              >InActive</span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ======================== product list start =========== -->
  <ng-container *ngIf="this.saleItem.products?.length !== 0">
    <section class="dark:bg-jacarta-800 py-16" id="productList">
      <div class="px-6 xl:px-24">
        <h1
          class="font-display text-jacarta-700 pb-12 text-4xl font-medium dark:text-white"
        >
          {{ saleItem?.sale_title }} Products
        </h1>

        <!-- Grid -->
        <div
          class="grid grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-5"
        >
          <article *ngFor="let products of productList" class="cursor-pointer">
            <app-product-card [products]="products"></app-product-card>
          </article>
        </div>
      </div>
    </section>
  </ng-container>
</ng-template>
