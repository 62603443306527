<ng-container *ngIf="nodata_container == true; else cartList">
  <section class="relative py-16 md:py-24">
    <div class="container">
      <div class="mx-auto max-w-lg text-center">
        <img src="assets/img/cart.png" alt="" class="mb-6 inline-block" />
        <h1
          class="text-jacarta-700 font-display mb-6 text-4xl dark:text-white md:text-6xl"
        >
          Your cart is empty.
        </h1>
        <p class="dark:text-jacarta-300 mb-12 text-lg leading-normal">
          Looks like you have not added any Products to your cart
        </p>
      </div>
    </div>
  </section>
</ng-container>
<ng-template #cartList>
  <!-- Cart Item -->
  <section class="relative py-24">
    <div class="px-6 xl:px-24">
      <ng-container *ngIf="dataRendered === false; else dataContainer">
        <div class="mt-16">
          <!-- <app-loader></app-loader> -->
          <app-cart-list-skelton></app-cart-list-skelton>
        </div>
      </ng-container>
      <ng-template #dataContainer>
        <h1
          class="font-display text-jacarta-700 py-16 text-4xl font-medium dark:text-white"
        >
          Cart Items
        </h1>
        <!-- Records / Filter -->
        <div class="lg:flex">
          <!-- Records -->
          <div class="mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pr-10">
            <!-- Online Products Start -->
            <ng-container *ngIf="onlineProducts.length > 0">
              <h4
                class="font-display text-jacarta-700 py-3 text-xl font-medium dark:text-white"
              >
                Selected Online Products
              </h4>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 relative flex flex-wrap items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg relative"
                *ngFor="let online of onlineProducts"
              >
                <figure class="mr-5 self-start">
                  <img
                    [src]="cloudFrontURL + online.product_images[0]"
                    [alt]="online.product_name"
                    class="rounded-2lg h-40"
                    loading="lazy"
                  />
                </figure>

                <div class="mr-5">
                  <a (click)="navigateToDetail(online)" class="cursor-pointer">
                    <h3
                      class="font-display text-jacarta-700 mb-4 text-lg font-semibold dark:text-white"
                    >
                      {{ online.product_name }}
                    </h3>
                  </a>

                  <div
                    class="mb-4 dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg cursor-pointer flex items-center"
                    (click)="switchSale('regular', online)"
                    [ngClass]="{
                      'bg-accent text-white': online.regular === true
                    }"
                  >
                    <div class="mr-2" *ngIf="online.regular === true">
                      <span
                        class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-[.875rem] w-[.875rem] fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <span class="text-sm mr-2 dark:text-white font-semibold"
                        >Regular Discount Price
                      </span>
                      <div class="flex flex-wrap items-center">
                        <span class="mr-2 text-green"
                          >₹
                          {{
                            online.product_price -
                              (online.product_price *
                                online.product_individual_discount) /
                                100
                          }}</span
                        >

                        <span class="text-sm mr-2 dark:text-white"
                          ><del>₹{{ online.product_price }}</del></span
                        >
                        <span class="text-sm text-red"
                          >{{ online.product_individual_discount }} % Off</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="items-center"
                    *ngIf="online?.prodcut_sale_data !== 0"
                  >
                    <ng-container
                      *ngFor="
                        let salelist of online?.prodcut_sale_data;
                        let i = index
                      "
                    >
                      <div
                        class="mb-4 flex dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg items-center cursor-pointer"
                        [ngClass]="{
                          'bg-accent text-white': online.regular === false
                        }"
                        (click)="switchSale(salelist, online)"
                      >
                        <div class="mr-2" *ngIf="online.regular === false">
                          <span
                            class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-[.875rem] w-[.875rem] fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path
                                d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        <div>
                          <span
                            class="text-sm mr-2 dark:text-white font-semibold"
                            >{{ salelist.sale_title }}
                          </span>
                          <div class="flex flex-wrap items-center">
                            <span class="mr-2 text-green"
                              >₹
                              {{
                                online.product_price -
                                  (online.product_price *
                                    (online.product_individual_discount +
                                      salelist.sale_discount)) /
                                    100
                              }}
                            </span>

                            <span class="text-sm mr-2 dark:text-white"
                              ><del>₹{{ online.product_price }}</del></span
                            >
                            <span class="text-sm text-red"
                              >({{ online.product_individual_discount }} +
                              {{ salelist.sale_discount }})% Discount</span
                            >
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="online.hasOwnProperty('purchased')">
                    <h4
                      class="font-display text-lg font-semibold text-jacarta-700 transition-colors text-red"
                    >
                      Already Purchased
                    </h4>
                  </ng-container>
                </div>
                <a
                  (click)="removeFromCart(online)"
                  class="top-3 right-3 absolute cursor-pointer"
                >
                  <svg
                    _ngcontent-serverApp-c15=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path
                      _ngcontent-serverApp-c15=""
                      fill="none"
                      d="M0 0h24v24H0z"
                    ></path>
                    <path
                      _ngcontent-serverApp-c15=""
                      d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    ></path>
                  </svg>
                </a>
              </div>
            </ng-container>
            <!-- Online Products End -->
            <!-- Offline Products Start -->
            <ng-container *ngIf="offlineProducts.length > 0">
              <h4
                class="font-display text-jacarta-700 py-3 text-xl font-medium dark:text-white"
              >
                Selected Offline Products
              </h4>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 relative flex items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg"
                *ngFor="let offline of offlineProducts"
              >
                <figure class="mr-5 self-start">
                  <img
                    [src]="cloudFrontURL + offline.product_images[0]"
                    [alt]="offline.product_name"
                    class="rounded-2lg h-40"
                    loading="lazy"
                  />
                </figure>

                <div>
                  <a (click)="navigateToDetail(offline)" class="cursor-pointer">
                    <h3
                      class="font-display text-jacarta-700 mb-1 text-lg font-semibold dark:text-white"
                    >
                      {{ offline.product_name }}
                    </h3>
                  </a>
                  <div class="mr-5">
                    <div
                      class="mb-4 dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg cursor-pointer flex items-center"
                      (click)="switchSale('regular', offline)"
                      [ngClass]="{
                        'bg-accent text-white': offline.regular === true
                      }"
                    >
                      <div class="mr-2" *ngIf="offline.regular === true">
                        <span
                          class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-[.875rem] w-[.875rem] fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path
                              d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                        <span class="text-sm mr-2 dark:text-white font-semibold"
                          >Regular Discount Price
                        </span>
                        <div class="flex flex-wrap items-center">
                          <span class="mr-2 text-green"
                            >₹
                            {{
                              offline.product_price -
                                (offline.product_price *
                                  offline.product_individual_discount) /
                                  100
                            }}</span
                          >

                          <span class="text-sm mr-2 dark:text-white"
                            ><del>₹{{ offline.product_price }}</del></span
                          >
                          <span class="text-sm text-red"
                            >{{ offline.product_individual_discount }} %
                            Off</span
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="items-center"
                      *ngIf="offline?.prodcut_sale_data !== 0"
                    >
                      <ng-container
                        *ngFor="
                          let salelist of offline?.prodcut_sale_data;
                          let i = index
                        "
                      >
                        <div
                          class="mb-4 flex dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg items-center cursor-pointer"
                          [ngClass]="{
                            'bg-accent text-white': offline.regular === false
                          }"
                          (click)="switchSale(salelist, offline)"
                        >
                          <div class="mr-2" *ngIf="offline.regular === false">
                            <span
                              class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                class="h-[.875rem] w-[.875rem] fill-white"
                              >
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                  d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <span
                              class="text-sm mr-2 dark:text-white font-semibold"
                              >{{ salelist.sale_title }}
                            </span>
                            <div class="flex flex-wrap items-center">
                              <span class="mr-2 text-green"
                                >₹
                                {{
                                  offline.product_price -
                                    (offline.product_price *
                                      (offline.product_individual_discount +
                                        salelist.sale_discount)) /
                                      100
                                }}
                              </span>

                              <span class="text-sm mr-2 dark:text-white"
                                ><del>₹{{ offline.product_price }}</del></span
                              >
                              <span class="text-sm text-red"
                                >({{ offline.product_individual_discount }} +
                                {{ salelist.sale_discount }})% Discount</span
                              >
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <a
                    (click)="removeFromCart(offline)"
                    class="top-3 right-3 absolute cursor-pointer"
                  >
                    <svg
                      _ngcontent-serverApp-c15=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="fill-jacarta-700 h-6 w-6 dark:fill-white"
                    >
                      <path
                        _ngcontent-serverApp-c15=""
                        fill="none"
                        d="M0 0h24v24H0z"
                      ></path>
                      <path
                        _ngcontent-serverApp-c15=""
                        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- Offline Products End -->
          </div>

          <!-- Side Panel Payment -->
          <aside class="basis-4/12 lg:pl-5">
            <h4
              class="font-display text-jacarta-700 py-3 text-xl font-medium dark:text-white"
            >
              Payment Information
            </h4>
            <div
              class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
            >
              <!-- Online Products Start -->
              <ng-container *ngIf="onlineProducts.length > 0">
                <h6
                  class="font-display text-jacarta-700 py-3 text-lg font-medium dark:text-white"
                >
                  Online Products Cost
                </h6>
                <div
                  class="mb-4 flex items-center justify-between text-sm font-medium tracking-tight"
                  *ngFor="let onlineP of onlineProducts"
                >
                  <div class="items-center">
                    <span class="mr-2 text-md dark:text-jacarta-300"
                      >{{ onlineP.product_name }}
                    </span>
                  </div>
                  <div class="items-center">
                    <ng-container
                      *ngIf="
                        onlineP.hasOwnProperty('selected_sale_discount');
                        else regularPriceContainer
                      "
                    >
                      <span class="mr-2 text-md text-green"
                        >₹{{
                          onlineP.product_price -
                            (onlineP.product_price *
                              (onlineP.product_individual_discount +
                                onlineP.selected_sale_discount)) /
                              100
                        }}
                      </span>
                    </ng-container>
                    <ng-template #regularPriceContainer>
                      <span class="mr-2 text-md text-green"
                        >₹{{
                          onlineP.product_price -
                            (onlineP.product_price *
                              onlineP.product_individual_discount) /
                              100
                        }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <!-- Online Products End -->
              <!-- Offline Products start -->
              <ng-container *ngIf="offlineProducts.length > 0">
                <h6
                  class="font-display text-jacarta-700 py-3 text-lg font-medium dark:text-white"
                >
                  Offline Products Cost
                </h6>
                <div
                  class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight"
                  *ngFor="let offlineP of offlineProducts"
                >
                  <div class="items-center">
                    <span class="mr-2 text-md dark:text-jacarta-300">{{
                      offlineP.product_name
                    }}</span>
                  </div>
                  <div class="items-center">
                    <ng-container
                      *ngIf="
                        offlineP.hasOwnProperty('selected_sale_discount');
                        else regularPriceContainer
                      "
                    >
                      <span class="mr-2 text-md text-green"
                        >₹{{
                          offlineP.product_price -
                            (offlineP.product_price *
                              (offlineP.product_individual_discount +
                                offlineP.selected_sale_discount)) /
                              100
                        }}
                      </span>
                    </ng-container>
                    <ng-template #regularPriceContainer>
                      <span class="mr-2 text-md text-green"
                        >₹{{
                          offlineP.product_price -
                            (offlineP.product_price *
                              offlineP.product_individual_discount) /
                              100
                        }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <!-- Offline Products End -->
              <!-- GST start -->
              <!-- <div
                class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight"
              >
                <div class="items-center">
                  <span class="mr-2 text-md dark:text-jacarta-300"
                    >GST(12%)
                  </span>
                </div>
                <div class="items-center">
                  <span class="mr-2 text-md text-green">₹{{ gstValue }}</span>
                </div>
              </div> -->
              <!-- GST End -->
              <hr class="mt-6 mb-6" />
              <div
                class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight"
              >
                <div class="items-center">
                  <span class="mr-2 text-lg dark:text-white">Total: </span>
                </div>
                <div class="items-center">
                  <span class="mr-2 text-lg text-green"
                    >₹{{ totalValue.toFixed(2) }}</span
                  >
                </div>
              </div>
              <div
                class="mt-6 mb-6 flex items-center justify-end text-sm font-medium tracking-tight"
              >
                <div class="items-center">
                  <ng-container *ngIf="loggedin == false; else logoutButton">
                    <button
                      class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
                      routerLink="/login"
                    >
                      Continue to Payment
                    </button>
                  </ng-container>
                  <ng-template #logoutButton>
                    <button
                      class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
                      data-bs-toggle="modal"
                      data-bs-target="#continuePaymentModal"
                      aria-label="continuePayment"
                    >
                      Continue to Payment
                    </button>
                  </ng-template>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </ng-template>
    </div>
  </section>
  <!-- end Cart items -->
</ng-template>

<!--================= Continue Payment Models start =====================-->
<div
  class="modal fade"
  id="continuePaymentModal"
  tabindex="-1"
  aria-labelledby="continuePaymentModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog max-w-2xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="continuePaymentModalLabel">
          Terms & Conditions
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeModal"
          #closebutton
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </button>
      </div>

      <!-- Body -->
      <div class="modal-body p-6">
        <div class="content-scroll">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </div>
      <!-- end body -->

      <div class="modal-footer">
        <div class="flex py-4 mb-6 items-center">
          <div class="mr-4 shrink-0">
            <input
              type="checkbox"
              id="saleItem"
              value=""
              class="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
              [(ngModel)]="agree"
            />
          </div>
          <div class="text-left">
            <span
              class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white"
              >I agree terms & conditions</span
            >
          </div>
        </div>
        <div class="text-right space-x-4">
          <button
            [disabled]="!agree"
            (click)="stripeCheckout()"
            class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          >
            Continue to Checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--================= Continue Payment  Models end =====================-->
