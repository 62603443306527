import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleService } from 'src/app/sharedServices/sale.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sale-detail',
  templateUrl: './sale-detail.component.html',
})
export class SaleDetailComponent implements OnInit {
  saleId: any;
  cloudFrontURL: any;

  constructor(
    private saleService: SaleService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.cloudFrontURL = environment.cloudFrontUrl;
  }
  ngOnInit(): void {
    this.saleId = this.activatedRoute.snapshot.params['id'];
    this.getSaleById(this.saleId);
  }

  saleItemJson: any;
  saleItem: any;
  productList: any;
  saleRendered: boolean = false;
  getSaleById(saleId: any) {
    this.saleService.getSaleSpecificItem(saleId).subscribe({
      next: (val: any) => {
        this.saleItemJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.saleItemJson.status === 1) {
          this.saleItem = this.saleItemJson.Sale;
          this.productList = this.saleItem.products;
          this.saleRendered = true;
          this.updateTitle(this.saleItem);
          // console.log(this.saleItem);
        }
      },
    });
  }
  // navigateToDetail(products: any) {
  //   const productName = products.product_name.split(' ').join('-');
  //   this.router.navigate(['/detail', productName, products.product_id]);
  // }

  // =========== MEta tags start ===================
  updateTitle(saleData: any) {
    this.title.setTitle(saleData?.sale_title);
  }
  // =========== meta tags end ====================
}
