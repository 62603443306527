import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { Observable, interval, map } from 'rxjs';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sale-card',
  templateUrl: './sale-card.component.html',
  styleUrls: ['./sale-card.component.css'],
})
export class SaleCardComponent implements OnInit {
  @Input() productData: any;
  projectURL: any;
  cloudFrontURL: any;
  constructor(private router: Router, public toastr: ToastrService) {
    this.projectURL = environment.projectURL;
    this.cloudFrontURL = environment.cloudFrontUrl;
  }
  ngOnInit(): void {
    // console.log(this.productSaleData);
    // this.getTimer();
  }

  // =============Navigating Sale detail Start==============
  navigateSaleDetail(saleDetail: any, saleType: any, id: any) {
    if (saleType === 0) {
      this.router.navigate(['sale-detail', saleDetail.sale_id]);
    } else if (saleType === 1) {
      this.router.navigate(['sale-detail', saleDetail.sale_id], {
        fragment: id,
      });
    }
  }
  // =============Navigating Sale detail End==============
  // ============== owl-carousel start ==================
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 10,
    autoplay: true,
    navSpeed: 700,
    navText: ['&#8249', '&#8250;'],
    lazyLoad: true,
    dotsEach: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  // =============== owl-carousel end =================
  // ======= copy url start===========
  copyClipboard(sale_data: any) {
    let url = this.projectURL + 'sale-detail/' + sale_data.sale_id;
    navigator.clipboard.writeText(url).then(() => {
      this.toastr.success(
        'Share the link anywhere you like',
        'URL Copied to clipboard '
      );
    });
  }
  // ======== copy url end===========
}
