<ng-container *ngIf="saleItemsExist == true; else homeSaleList">
  <!-- <h2 class="font-display text-jacarta-700 py-16 text-3xl dark:text-white">
    Currently No Running Sales
  </h2> -->
  <div class="py-24"></div>
</ng-container>
<ng-template #homeSaleList>
  <section class="relative py-24">
    <div class="px-6 xl:px-24">
      <ng-container *ngIf="datarendering === false; else saleListContainer">
        <app-sale-list-skelton></app-sale-list-skelton>
      </ng-container>
      <ng-template #saleListContainer>
        <h2
          class="font-display text-jacarta-700 py-16 text-3xl dark:text-white"
        >
          Current Running Sales
        </h2>
        <div
          class="grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2 md:grid-cols-3"
        >
          <article *ngFor="let saleItem of saleItems">
            <div
              class="overflow-hidden rounded-[1.25rem] transition-shadow hover:shadow-lg cursor-pointer"
              [routerLink]="['/sale-detail', saleItem.sale_id]"
            >
              <figure class="group overflow-hidden max-h-48">
                <img
                  [src]="cloudFrontURL + saleItem.sale_image_one"
                  [alt]="saleItem.sale_title"
                  class="h-full w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                />
              </figure>

              <!-- Body -->
              <div
                class="dark:border-jacarta-600 dark:bg-jacarta-700 border-jacarta-100 rounded-b-[1.25rem] border border-t-0 bg-white p-[10%]"
              >
                <h2
                  class="font-display text-jacarta-700 dark:hover:text-accent hover:text-accent mb-4 text-xl dark:text-white"
                >
                  {{ saleItem.sale_title }}
                </h2>
                <p
                  class="dark:text-jacarta-200 mb-8 text-ellipsis-2"
                  [innerHTML]="saleItem.sale_description"
                ></p>

                <!-- Date / Time -->
                <div
                  class="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm"
                >
                  <span class="text-red"
                    >Ends on {{ saleItem.sale_end_date | date : "dd-MM-yyyy" }}
                    <!-- {{ saleItem.days }} Days {{ saleItem.hours }}Hrs
                    {{ saleItem.minutes }}Mins {{ saleItem.seconds }}Sec -->
                  </span>
                </div>
              </div>
            </div>
          </article>
        </div>
      </ng-template>
    </div>
  </section>
</ng-template>
