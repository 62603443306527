import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../sharedServices/website.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  constructor(private websiteService: WebsiteService) {}
  ngOnInit(): void {
    this.getFooterData();
  }

  footerJsonData: any;
  footerData: any;
  getFooterData() {
    this.websiteService.getFooterData().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.footerJsonData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.footerJsonData.status === 1) {
          this.footerData = this.footerJsonData.footer[0];
        }
      },
    });
  }
}
