import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-sale-countdown-card',
  templateUrl: './sale-countdown-card.component.html',
  styleUrls: ['./sale-countdown-card.component.css'],
})
export class SaleCountdownCardComponent implements OnInit {
  @Input() productData: any;
  ngOnInit(): void {
    // console.log(this.productData);
    this.getTimer();
  }
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  countDownTimer: any;
  getTimer() {
    this.productData?.prodcut_sale_data.map((item: any) => {
      setInterval(() => {
        this.countDownTimer = new Date(item.sale_end_date).getTime();
        var now = new Date().getTime();
        var distance = this.countDownTimer - now;
        if (distance <= 0) {
          item.sale_end = 'Sale Was ended';
        } else {
          item.days = Math.floor(distance / (1000 * 60 * 60 * 24));
          item.hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          item.minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          item.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        }
      });
    });
    // console.log('Product Sale Data', this.productData.prodcut_sale_data);
  }

  // ============== owl-carousel start ==================
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 10,
    autoplay: true,
    navSpeed: 700,
    navText: ['&#8249', '&#8250;'],
    lazyLoad: true,
    dotsEach: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  // =============== owl-carousel end =================
}
