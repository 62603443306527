import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/sharedServices/cart.service';
import { ProductService } from 'src/app/sharedServices/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
})
export class PaymentSuccessComponent {
  selectedProducts: any;
  nodata_container: boolean = false;
  totalValue: number;
  gstValue: number;
  finalPrice: number;

  cloudFrontURL: any;
  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private router: Router
  ) {
    this.cloudFrontURL = environment.cloudFrontUrl;
  }
  ngOnInit(): void {
    let localstorageIDs = localStorage.getItem('products');
    this.selectedProducts = localstorageIDs ? JSON.parse(localstorageIDs) : [];
    this.cartedProducts();
    setTimeout(() => {
      this.cartService.getCartValue(0);
    }, 2000);
    setTimeout(() => {
      this.router.navigate(['profile']);
      localStorage.removeItem('products');
    }, 10000);
  }

  cartedProducts() {
    this.onlineProducts = [];
    this.offlineProducts = [];
    this.allProducts = [];
    this.totalValue = 0;
    if (this.selectedProducts.length == 0) {
      this.nodata_container = true;
    }
    this.selectedProducts.map((productObj: any) => {
      this.getProductByid(productObj);
    });
  }

  // ================ get Product data start ===============
  productJson: any;
  productData: any;
  dataRendered: boolean = false;
  allProducts: any = [];
  onlineProducts: any = [];
  offlineProducts: any = [];

  getProductByid(productObj: any) {
    this.productService.getProductByid(productObj.product_id).subscribe({
      next: (val: any) => {
        this.productJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.productJson.status === 1) {
          this.productData = this.productJson.product;
          // this.productData.is_cart = false;
          // console.log('Before', this.productData);
          if (this.productData.product_sale.length > 0) {
            this.productData.prodcut_sale_data.map((saleData: any) => {
              if (productObj.hasOwnProperty('sale_id') === true) {
                if (saleData.sale_id === productObj.sale_id) {
                  saleData.selected = true;
                  this.productData.selected_sale_discount =
                    saleData.sale_discount;
                  this.productData.regular = false;
                }
              } else {
                this.productData.regular = true;
              }
            });
          } else {
            this.productData.regular = true;
          }

          if (this.productData.product_type === 0) {
            this.onlineProducts.push(this.productData);
          } else if (this.productData.product_type === 1) {
            this.offlineProducts.push(this.productData);
          }
          this.allProducts.push(this.productData);
          let cost = 0;
          this.allProducts.map((products: any) => {
            if (products.hasOwnProperty('selected_sale_discount') === true) {
              this.finalPrice =
                products.product_price -
                (products.product_price *
                  (products.product_individual_discount +
                    products.selected_sale_discount)) /
                100;
            } else {
              this.finalPrice =
                products.product_price -
                (products.product_price *
                  products.product_individual_discount) /
                100;
            }
            cost += this.finalPrice;
          });

          if (this.selectedProducts.length === this.allProducts.length) {
            this.gstValue = (cost * 12) / 100;
            this.totalValue = cost;
          }
          this.dataRendered = true;
        }
      },
    });
  }

  // ===================Get product Data end ===================
  ngOnDestroy() {
    localStorage.removeItem('products');
  }
}
