<ng-container *ngIf="dataRendered == false; else productDetailContainer">
  <section class="relative pt-12 pb-24 lg:py-24 mt-24">
    <div class="px-6 xl:px-24 text-center">
      <!-- <app-loader></app-loader> -->
      <app-product-detail-skelton></app-product-detail-skelton>
    </div>
  </section>
</ng-container>
<ng-template #productDetailContainer>
  <!-- Item -->
  <section class="relative pt-12 pb-24 lg:py-24 mt-24">
    <div class="px-6 xl:px-24">
      <nav aria-label="breadcrumb mb-8">
        <ol class="flex items-center mb-8">
          <li class="px-2">
            <a routerLink="/home" class="dark:text-white cursor-pointer"
              >Home</a
            >
            /
          </li>
          <li *ngFor="let cat of categories; let i = index">
            <a
              *ngIf="i === productData?.product_type"
              (click)="navigateToSection('categories')"
              class="cursor-pointer dark:text-white px-2"
              >{{ cat }} /</a
            >
          </li>
          <li aria-current="page" class="text-green">
            {{ productData?.product_name }}
          </li>
        </ol>
      </nav>
      <div class="md:flex md:flex-wrap">
        <!-- Image -->
        <div
          class="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2"
        >
          <owl-carousel-o [options]="customOptions">
            <ng-container
              *ngFor="
                let productImage of productData?.product_images;
                let i = index
              "
            >
              <ng-template carouselSlide>
                <img
                  [src]="cloudFrontUrl + productImage"
                  [alt]="productData?.product_name"
                  class="cursor-pointer rounded-[1.25rem]"
                />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>

        <!--Right Side Details -->
        <div class="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
          <div class="mb-3 items-center">
            <!-- Likes / Actions -->
            <div class="ml-auto flex justify-end mb-4 space-x-2">
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4"
                (click)="copyClipboard()"
              >
                <span
                  class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('assets/img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                  data-tippy-content="Favorite"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      d="M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4"
              >
                <span
                  class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('assets/img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                  data-tippy-content="Favorite"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-eye mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"
                    />
                    <path
                      d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
                    />
                  </svg>
                </span>
                <span class="dark:text-jacarta-200 text-sm">{{
                  productData?.product_views
                }}</span>
              </div>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4"
              >
                <span
                  class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('assets/img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                  data-tippy-content="Favorite"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4 mr-1"
                  >
                    <path fill="none" d="M0 0H24V24H0z"></path>
                    <path
                      d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z"
                    ></path>
                  </svg>
                </span>
                <span class="dark:text-jacarta-200 text-sm">{{
                  productData?.product_likes
                }}</span>
              </div>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4"
              >
                <span
                  class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('assets/img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                  data-tippy-content="Favorite"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-star-fill mr-1 text-yellow"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                    />
                  </svg>
                </span>
                <ng-container
                  *ngIf="
                    productData?.product_rating === null;
                    else ratingContainer
                  "
                >
                  <span class="dark:text-jacarta-200 text-sm">0</span>
                </ng-container>
                <ng-template #ratingContainer>
                  <span class="dark:text-jacarta-200 text-sm">{{
                    productData?.product_rating
                  }}</span>
                </ng-template>
              </div>
            </div>
            <!-- Collection -->
            <div class="items-center">
              <h1
                class="font-display text-jacarta-700 text-xl font-semibold dark:text-white"
              >
                {{ productData?.product_name }}
              </h1>
            </div>
          </div>
          <!-- Price start -->
          <div class="mb-8 flex items-center space-x-4 whitespace-nowrap">
            <div class="flex items-center">
              <span class="text-green font-medium tracking-tight"
                >₹
                {{
                  productData?.product_price -
                    (productData?.product_price *
                      productData?.product_individual_discount) /
                      100
                }}</span
              >
            </div>
            <span class="dark:text-jacarta-300 text-jacarta-400 text-sm"
              ><del>₹ {{ productData?.product_price }}</del></span
            >
            <span class="text-jacarta-400 text-sm text-red"
              >{{ productData?.product_individual_discount }}% Discount</span
            >
          </div>
          <!-- Price end -->

          <div
            class="dark:text-jacarta-300 mb-6"
            [innerHTML]="productData?.product_description"
          ></div>
          <div class="mb-6">
            <div class="flex flex-wrap">
              <span
                class="bg-green group mr-2.5 mb-2.5 inline-flex items-center rounded-xl px-4 py-2 hover:border-transparent dark:hover:border-transparent text-white"
                *ngIf="productData?.product_type == 0"
              >
                This is an <span class="mx-2 text-blue"> Online </span> Product
              </span>
              <span
                class="bg-green group mr-2.5 mb-2.5 inline-flex items-center rounded-xl px-4 py-2 hover:border-transparent text-white dark:hover:border-transparent"
                *ngIf="productData?.product_type == 1"
              >
                This is an
                <span class="mx-2 text-blue"> Offline </span> Product
              </span>
            </div>
          </div>
          <div class="mb-6">
            <div class="flex flex-wrap">
              <!-- dark:border-jacarta-600 dark:bg-jacarta-700 group dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border bg-white px-4 py-2 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent -->
              <button
                class="cursor-pointer dark:border-jacarta-600 group h-8 dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border px-2 py-2 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent text-sm"
                *ngFor="let productTags of productData?.product_tags"
              >
                <span class="text-green">#</span>{{ productTags }}
              </button>
            </div>
          </div>
          <ng-container *ngIf="productData?.prodcut_usecase_data?.length > 0">
            <div class="transition-shadow mb-6">
              <span class="dark:text-jacarta-300">Usecases: </span>
              <a
                href="{{ studioWebsiteURL }}usecases/details/{{
                  productUsecase.usecase_id
                }}"
                target="_blank"
                class="rounded bg-green py-1 px-2 text-xxs leading-none text-white mr-2 mb-10"
                *ngFor="let productUsecase of productData?.prodcut_usecase_data"
              >
                {{ productUsecase.usecase_title }}
              </a>
            </div>
          </ng-container>

          <!-- View Button  -->
          <div class="mb-6 flex flex-wrap">
            <div
              class="mr-8 mb-2 flex"
              *ngIf="productData?.product_ar_demo?.length !== 0"
            >
              <button
                class="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded bg-white py-2 px-8 text-center font-semibold transition-all hover:text-white"
                data-bs-toggle="modal"
                data-bs-target="#viewDemoModal"
                aria-label="viewDemo"
              >
                View Demo
              </button>
            </div>

            <div
              class="mr-8 mb-2 flex"
              *ngIf="productData?.product_video_gif !== ''"
            >
              <button
                class="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded bg-white py-2 px-8 text-center font-semibold transition-all hover:text-white"
                data-bs-toggle="modal"
                data-bs-target="#viewVideoModal"
                aria-label="viewVideo"
              >
                View Video
              </button>
            </div>
            <!-- <div class="mb-2 flex" *ngIf="productData?.product_type === 0">
              <button
                class="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded bg-white py-2 px-8 text-center font-semibold transition-all hover:text-white"
                data-bs-toggle="modal"
                data-bs-target="#viewModal"
                aria-label="viewModal"
              >
                View Model
              </button>
            </div> -->
          </div>
          <!-- View button end -->
          <!-- Add to cart -->
          <div class="flex flex-wrap items-center">
            <div class="ml-2">
              <ng-container
                *ngIf="
                  productData.hasOwnProperty('purchased');
                  else addtoCartContainer
                "
              >
                <h4
                  class="font-display text-lg font-semibold text-jacarta-700 transition-colors text-red"
                >
                  Already Purchased
                </h4>
              </ng-container>
              <ng-template #addtoCartContainer>
                <ng-container
                  *ngIf="
                    !productData?.is_cart &&
                    productData?.prodcut_sale_data?.length === 0
                  "
                >
                  <a
                    (click)="
                      productData?.is_cart
                        ? removeCart(productData)
                        : addToCart(productData)
                    "
                    class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
                  >
                    Add to Cart
                  </a>
                </ng-container>
                <ng-container
                  *ngIf="
                    !productData?.is_cart &&
                    productData?.prodcut_sale_data?.length !== 0
                  "
                >
                  <a
                    class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
                    data-bs-toggle="modal"
                    data-bs-target="#saleCartModal"
                    aria-label="saleCartModal"
                  >
                    Add to Cart
                  </a>
                </ng-container>
                <a
                  *ngIf="productData?.is_cart"
                  (click)="removeCart(productData)"
                  class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
                >
                  Remove From Cart
                </a>
              </ng-template>
            </div>
            <div class="my-1 ml-2">
              <ng-container
                *ngIf="productData?.product_purchases === 0; else purchaseCount"
              >
                <p
                  class="font-display text-lg font-semibold text-jacarta-700 transition-colors text-green"
                >
                  (New Product)
                </p>
              </ng-container>
              <ng-template #purchaseCount>
                <p
                  class="font-display text-md font-semibold text-jacarta-700 transition-colors dark:text-white"
                >
                  <span class="text-green text-lg"
                    >{{ productData?.product_purchases }} </span
                  >people purchased this product
                </p>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- Right Side end details -->
      </div>
      <!-- Likes and rating -->
      <div class="container">
        <div
          class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-2 mt-6 mb-6 items-center"
        >
          <article>
            <div
              class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
            >
              <div class="flex flex-wrap items-center justify-center">
                <div class="ml-2">
                  <span
                    class="center font-semibold dark:text-white transition-all mr-2"
                  >
                    Do you like Product :
                  </span>
                </div>
                <div
                  class="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white mr-2"
                >
                  <button
                    class="js-likes relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm dark:text-white"
                    (click)="addLikes(productData?.product_id, 1)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-hand-thumbs-up-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  class="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white"
                >
                  <button
                    class="js-likes relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm dark:text-white"
                    (click)="addLikes(productData?.product_id, 0)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-hand-thumbs-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.38 1.38 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51q.205.03.443.051c.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.9 1.9 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2 2 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.2 3.2 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.8 4.8 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </article>
          <article>
            <div
              class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg flex flex-wrap justify-center items-center"
            >
              <span
                class="center font-semibold dark:text-white transition-all mr-2"
                >Rate Product :</span
              >
              <span class="flex flex-wrap text-4xl gap-3">
                <ng-container *ngFor="let star of stars">
                  <i
                    class="fa-solid fa fa-star cursor-pointer"
                    (click)="updateRating(productData?.product_id, star)"
                    [ngClass]="{ checked: star <= ratedCount }"
                  ></i>
                </ng-container>
              </span>
            </div>
          </article>
        </div>
      </div>
      <!-- Sale Countdown Card start -->
      <ng-container *ngIf="productData?.prodcut_sale_data?.length !== 0">
        <div class="container">
          <app-sale-countdown-card
            *ngIf="dataRendered"
            [productData]="productData"
          ></app-sale-countdown-card>
        </div>
      </ng-container>
      <!-- Sale Countdown Card End -->
      <!-- Tabs Start -->
      <app-product-detail-tabs
        *ngIf="dataRendered"
        [productDataTabs]="productData"
      ></app-product-detail-tabs>
      <!-- Tabs end -->

      <!-- Related Products Category start -->
      <div id="categories">
        <app-related-prooducts
          *ngIf="dataRendered"
          [products]="productData?.category_products"
          [productType]="productData?.product_category"
        ></app-related-prooducts>
      </div>
      <!-- Related Products Category end -->
      <!-- Related Products Publisher start -->
      <div id="publisher">
        <app-related-prooducts
          *ngIf="dataRendered"
          [products]="productData?.publisher_products"
          [productType]="productData?.publisher_data"
        ></app-related-prooducts>
      </div>
      <!-- Related Products Publisher end -->
      <!-- Sale card lIst start -->
      <app-sale-card [productData]="productData"></app-sale-card>
      <!-- Sale card End -->
    </div>
  </section>
  <!-- end item -->
</ng-template>

<!--================= Add to cart Sale List Popup start =====================-->
<div
  class="modal fade"
  id="saleCartModal"
  tabindex="-1"
  aria-labelledby="saleCartModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog max-w-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="saleCartModalLabel">
          Looks like this product is on sale
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </button>
      </div>

      <!-- Body -->
      <div class="modal-body p-6">
        <h4 class="font-semibold dark:text-white transition-all mb-6">
          Choose a Sale (Optional)
        </h4>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 flex rounded-[1.25rem] border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-jacarta-600 mb-6"
          *ngFor="let sales of productData?.prodcut_sale_data; let i = index"
        >
          <div class="mr-4 shrink-0">
            <input
              type="checkbox"
              id="saleItem"
              name="saleItem"
              value="1"
              class="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
              (change)="getSale($event, sales, i)"
              [ngModel]="isChecked === i"
            />
          </div>
          <div class="text-left">
            <span
              class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white block"
              >{{ sales.sale_title }}</span
            >

            <span class="dark:text-jacarta-300 text-sm block mb-2"
              >Sale Discount: {{ sales.sale_discount }}%</span
            >

            <div class="flex items-center space-x-4 whitespace-nowrap">
              <div class="flex items-center">
                <span class="text-green font-medium tracking-tight"
                  >₹
                  {{
                    productData?.product_price -
                      (productData?.product_price *
                        (productData?.product_individual_discount +
                          sales.sale_discount)) /
                        100
                  }}</span
                >
              </div>
              <span class="dark:text-jacarta-300 text-jacarta-400 text-sm"
                ><del>₹ {{ productData?.product_price }}</del></span
              >
              <span class="text-jacarta-400 text-sm text-red"
                >({{ productData?.product_individual_discount }} +{{
                  sales.sale_discount
                }})% Discount</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end body -->

      <div class="modal-footer">
        <div class="flex items-center justify-end space-x-4">
          <a
            *ngIf="!productData?.is_cart"
            (click)="
              productData?.is_cart
                ? removeCart(productData)
                : addToCart(productData)
            "
            class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
            data-bs-dismiss="modal"
          >
            Add to Cart
          </a>
          <a
            *ngIf="productData?.is_cart"
            (click)="removeCart(productData)"
            class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all"
            data-bs-dismiss="modal"
          >
            Remove From Cart
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!--================= Add to cart Sale List Popup end =====================-->
<!--================= View Demo Models start =====================-->
<div
  class="modal fade"
  id="viewDemoModal"
  tabindex="-1"
  aria-labelledby="viewDemoModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog max-w-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewDemoModalLabel">View Demo</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </button>
      </div>

      <!-- Body -->
      <div class="modal-body p-6 text-center">
        <a
          [href]="studioWebsiteURL + '2D/details/' + hybrid.demo_id"
          target="_blank"
          class="border-jacarta-100 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex rounded-[1.25rem] border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent mb-8 shadow-lg"
          *ngFor="let hybrid of productData?.product_ar_demo_data"
        >
          <figure class="mr-4 shrink-0">
            <div class="relative block">
              <img
                [src]="cloudFrontUrl + hybrid.demo_marker"
                [alt]="hybrid.demo_name"
                class="rounded-2lg w-\[100px\]"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
          </figure>
          <div class="text-left">
            <div class="block">
              <span
                class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white"
                >{{ hybrid.demo_name }}</span
              >
            </div>
            <span class="dark:text-jacarta-300 text-sm">Hybrid Card</span>
          </div>
        </a>
        <a
          [href]="studioWebsiteURL + '3D/mono/details/' + mono.model_id"
          target="_blank"
          class="border-jacarta-100 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex rounded-[1.25rem] border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent mb-8 shadow-lg"
          *ngFor="let mono of productData?.product_mono_demo_data"
        >
          <figure class="mr-4 shrink-0">
            <div class="relative block">
              <img
                [src]="cloudFrontUrl + mono.model_gif"
                [alt]="mono.model_name"
                class="rounded-2lg w-\[100px\]"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
          </figure>
          <div class="text-left">
            <div class="block">
              <span
                class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white"
                >{{ mono.model_name }}</span
              >
            </div>
            <span class="dark:text-jacarta-300 text-sm">Pure Mono Card</span>
          </div>
        </a>
        <a
          [href]="studioWebsiteURL + '3D/multi/details/' + multi.demo_id"
          target="_blank"
          class="border-jacarta-100 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex rounded-[1.25rem] border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent mb-8 shadow-lg"
          *ngFor="let multi of productData?.product_multi_demo_data"
        >
          <figure class="mr-4 shrink-0">
            <div class="relative block">
              <img
                [src]="cloudFrontUrl + multi.demo_image"
                [alt]="multi.demo_name"
                class="rounded-2lg"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
          </figure>
          <div class="text-left">
            <div class="block">
              <span
                class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white"
                >{{ multi.demo_name }}</span
              >
            </div>
            <span class="dark:text-jacarta-300 text-sm">Pure Multi Card</span>
          </div>
        </a>
      </div>
      <!-- end body -->
    </div>
  </div>
</div>
<!--================= View demo  Models end =====================-->

<!--================= View Video Models start =====================-->
<div
  class="modal fade"
  id="viewVideoModal"
  tabindex="-1"
  aria-labelledby="viewVideoModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog max-w-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewVideoModalLabel">View Video</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </button>
      </div>

      <!-- Body -->
      <div class="modal-body p-6 text-center">
        <p class="text-center dark:text-white">
          You don't have MetaMask in your browser, please download it from
        </p>
      </div>
      <!-- end body -->
    </div>
  </div>
</div>
<!--================= View Video  Models end =====================-->
<!--================= View Models start =====================-->
<div
  class="modal fade"
  id="viewModal"
  tabindex="-1"
  aria-labelledby="viewModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog max-w-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="viewModalLabel">View Model</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
        </button>
      </div>

      <!-- Body -->
      <div class="modal-body p-6 text-center">
        <p class="text-center dark:text-white">
          You don't have MetaMask in your browser, please download it from
        </p>
      </div>
      <!-- end body -->

      <!-- <div class="modal-footer">
        <div class="flex items-center justify-center space-x-4">
          <a
            href="https://metamask.io/"
            target="_blank"
            rel="noreferrer noopener"
            class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          >
            Get Metamask
          </a>
        </div>
      </div> -->
    </div>
  </div>
</div>
<!--================= View Models end =====================-->
