<owl-carousel-o [options]="customOptions">
  <ng-container
    *ngFor="let saleData of productData?.prodcut_sale_data; let i = index"
  >
    <ng-template carouselSlide>
      <div class="pt-[5.5rem] lg:pt-24">
        <!-- Banner -->
        <div class="relative">
          <img
            [src]="cloudFrontURL + saleData.sale_image_one"
            [alt]="saleData.sale_title"
            class="h-[18.75rem] w-full object-cover"
          />
        </div>
        <!-- end banner -->
      </div>
      <div class="dark:bg-jacarta-800 bg-light-base relative pb-12 pt-28">
        <!-- Avatar -->
        <div
          class="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center"
        >
          <figure class="relative">
            <img
              src="assets/img/sale.png"
              alt=""
              class="dark:border-jacarta-600 rounded-xl border-[5px] border-white sale-static-image"
            />
            <div
              class="dark:border-jacarta-600 bg-green absolute -right-3 bottom-0 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
              data-tippy-content="Verified Collection"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="h-[.875rem] w-[.875rem] fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                ></path>
              </svg>
            </div>
          </figure>
        </div>
        <!-- Avtar end -->
        <div class="container">
          <div class="text-center">
            <h2
              class="font-display text-jacarta-700 mb-2 text-4xl font-medium dark:text-white"
            >
              {{ saleData.sale_title }}
            </h2>

            <div
              class="dark:bg-jacarta-800 dark:border-jacarta-600 border-jacarta-100 mb-8 inline-flex flex-wrap items-center justify-center rounded-xl border bg-white"
            >
              <a
                href="#"
                class="dark:border-jacarta-600 border-jacarta-100 w-1/2 rounded-l-xl border-r py-4 hover:shadow-md sm:w-32"
              >
                <div
                  class="text-jacarta-700 mb-1 text-base font-bold dark:text-white"
                >
                  {{ saleData.sale_discount }}%
                </div>
                <div
                  class="text-2xs dark:text-jacarta-400 font-medium tracking-tight"
                >
                  Discount
                </div>
              </a>
              <a
                href="#"
                class="dark:border-jacarta-600 border-jacarta-100 w-1/2 py-4 hover:shadow-md sm:w-32 sm:border-r"
              >
                <div
                  class="text-jacarta-700 mb-1 text-base font-bold dark:text-white"
                >
                  {{ saleData.sale_start_date | date : "dd-MM-yyyy" }}
                </div>
                <div
                  class="text-2xs dark:text-jacarta-400 font-medium tracking-tight"
                >
                  Start Date
                </div>
              </a>
              <a
                href="#"
                class="dark:border-jacarta-600 border-jacarta-100 w-1/2 border-r py-4 hover:shadow-md sm:w-32"
              >
                <div
                  class="text-jacarta-700 mb-1 flex items-center justify-center text-base font-medium dark:text-white"
                >
                  <span class="font-bold">{{
                    saleData.sale_end_date | date : "dd-MM-yyyy"
                  }}</span>
                </div>
                <div
                  class="text-2xs dark:text-jacarta-400 font-medium tracking-tight"
                >
                  End Date
                </div>
              </a>
              <a
                href="#"
                class="border-jacarta-100 w-1/2 rounded-r-xl py-4 hover:shadow-md sm:w-32"
              >
                <div
                  class="text-jacarta-700 mb-1 flex items-center justify-center text-base font-medium dark:text-white"
                >
                  <span class="font-bold">Active</span>
                  <!-- <span class="font-bold" *ngIf="saleData.sale_state == 1"
                    >Inactive</span
                  > -->
                </div>
                <div
                  class="text-2xs dark:text-jacarta-400 font-medium tracking-tight"
                >
                  Sale Status
                </div>
              </a>
            </div>

            <p
              class="dark:text-jacarta-300 text-md"
              [innerHTML]="saleData.sale_description"
            ></p>

            <div class="mt-6 flex flex-wrap items-center justify-center">
              <a
                (click)="navigateSaleDetail(saleData, 0, '')"
                class="cursor-pointer text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded bg-white py-2 px-8 text-center font-semibold transition-all hover:text-white mr-4"
                >View Sale Details</a
              >
              <a
                (click)="navigateSaleDetail(saleData, 1, 'productList')"
                class="cursor-pointer text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded bg-white py-2 px-8 text-center font-semibold transition-all hover:text-white mr-4"
                >View all products of this sale</a
              >
              <div
                class="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white mr-4"
              >
                <div
                  class="js-likes relative inline-flex h-10 w-10 cursor-pointer items-center justify-center text-sm before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                  role="button"
                  data-tippy-content="Favorite"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4"
                  >
                    <path fill="none" d="M0 0H24V24H0z" />
                    <path
                      d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="dark:border-jacarta-600 dark:hover:bg-jacarta-600 border-jacarta-100 dropdown hover:bg-jacarta-100 dark:bg-jacarta-700 rounded-xl border bg-white"
              >
                <a
                  (click)="copyClipboard(saleData)"
                  class="inline-flex h-10 w-10 items-center justify-center text-sm"
                  role="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark:fill-jacarta-200 fill-jacarta-500 h-4 w-4"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
