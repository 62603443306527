import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../sharedServices/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  jwtHelper = new JwtHelperService();
  strpe_secret_key: any;
  constructor(
    private authService: AuthenticationService,
    public toatsr: ToastrService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // var userdata = this.authService.userInfo.getValue();
    let authReq = req;
    let token = this.authService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(
      catchError((errordata: any) => {
        if (
          (errordata instanceof HttpErrorResponse &&
            errordata.status === 401 &&
            errordata.error.message == 'Auth Failed') ||
          (errordata.status === 500 &&
            errordata.error.message.name === 'TokenExpiredError')
        ) {
          return this.handleRefreshToken(req, next);
        }
        return throwError(errordata);
      })
    );
  }

  handleRefreshToken(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authService.getrefreshToken();
      if (token) {
        return this.authService.userefreshToken().pipe(
          switchMap((newTokenpayload: any) => {
            this.isRefreshing = false;
            localStorage.setItem('access_token', newTokenpayload.token);
            localStorage.setItem(
              'refresh_token',
              newTokenpayload.refresh_token
            );
            const decrypteduser = this.jwtHelper.decodeToken(
              newTokenpayload.token
            );

            localStorage.setItem('expiration', decrypteduser.exp);
            this.authService.userInfo.next(decrypteduser);
            return next.handle(this.addTokenHeader(req, newTokenpayload.token));
          }),
          catchError((errordata: any) => {
            this.isRefreshing = false;
            this.authService.userLogout();
            this.toatsr.error('Your Session was expired');
            return throwError(errordata);
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(req, token)))
    );
  }

  addTokenHeader(req: HttpRequest<any>, access_token: any) {
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${access_token}`),
    });
  }
}
