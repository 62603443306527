import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SaleService {
  apiKey: any;
  constructor(private httpClient: HttpClient) {
    this.apiKey = environment.apiUrl;
  }

  getSaleItems() {
    return this.httpClient.get(this.apiKey + 'shop/sale/saleFilterByState/1');
  }
  getSaleSpecificItem(saleId: any) {
    return this.httpClient.get(this.apiKey + 'shop/sale/' + saleId);
  }
}
