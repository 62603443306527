import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { CartService } from 'src/app/sharedServices/cart.service';

import { ProductService } from 'src/app/sharedServices/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
})
export class ProductListComponent implements OnInit {
  categories = ['3DModel', 'Books', 'Posters', 'Coasters', 'Stickers', 'All'];
  categoryName: any = '';
  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {
    if (this.activatedRoute.snapshot.params['category']) {
      this.categoryName = this.activatedRoute.snapshot.params['category'];
      // console.log(this.categoryName);
    }
  }
  ngOnInit(): void {
    this.getAllProducts('lot');
  }

  //  =============== getallProducts start=================
  productListJson: any;
  productList: any;
  dataFetched: boolean = false;
  unSortedProductedList: any;
  noDataFound: any;
  getAllProducts(sortType: any) {
    this.productService.getAllProducts(sortType).subscribe({
      next: (val: any) => {
        this.productListJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.productListJson.status === 1) {
          this.productList = this.productListJson.products;
          this.unSortedProductedList = this.productListJson.products;
          this.dataFetched = true;
          this.getProductTags();
          if (this.categoryName !== '') {
            if (this.categoryName === 'Immersion Software Labs Pvt.Ltd.') {
              this.filterData(0, 'publisher', this.categoryName);
            } else if (this.categoryName === 0) {
              this.filterData(0, 'category', '3DModel');
            } else if (this.categoryName === 1) {
              this.filterData(1, 'category', 'Books');
            } else if (this.categoryName === 2) {
              this.filterData(2, 'category', 'Posters');
            } else if (this.categoryName === 3) {
              this.filterData(3, 'category', 'Coasters');
            } else if (this.categoryName === 4) {
              this.filterData(4, 'category', 'Stickers');
            }
          }
          this.productPagination();
          this.getAllActivePublishers();
        }
      },
    });
  }
  //  =================== getall products end =============

  //========= Sort by Price Start=================
  tickIcon: number = 3;
  selectedSort: any = 'Latest on top';
  sortProductByPrice(option: any) {
    // this.productList = [];
    if (option == 'l2h') {
      this.productList.sort(
        (a: any, b: any) => Number(a.product_price) - Number(b.product_price)
      );
      this.tickIcon = 4;
      this.selectedSort = 'Price low to high';
    } else if (option == 'h2l') {
      this.productList.sort(
        (a: any, b: any) => Number(b.product_price) - Number(a.product_price)
      );
      this.tickIcon = 5;
      this.selectedSort = 'Price high to low';
    } else if (option == 'sale') {
      this.tickIcon = 1;
      this.getAllSaleProducts();
      this.selectedSort = 'Sale';
    } else if (option == 'oot') {
      this.getAllProducts('oot');
      this.tickIcon = 2;
      this.selectedSort = 'Oldest on top';
    } else if (option == 'lot') {
      this.getAllProducts('lot');
      this.tickIcon = 3;
      this.selectedSort = 'Latest on top';
    }
    this.productPagination();
  }
  //========= Sort by Price Start end=================

  // ===================== sort by sale prodcuts end =============
  saleProductsJson: any;
  getAllSaleProducts() {
    this.productService.getSaleAllProducts().subscribe({
      next: (val: any) => {
        this.saleProductsJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.saleProductsJson.status === 1) {
          this.productList = this.saleProductsJson.sale_Products;
          this.productPagination();
        }
      },
    });
  }
  // ===================== sort by sale prodcuts end =============
  // ========== category sorting start =====================

  activeCategory: any = this.categories.length - 1;
  publisher: any = [];
  activePublisher: any;
  filteredData: any = [];
  filteredTags: any = [];
  filterData(index: any, type: any, name: any) {
    this.productList = [];
    this.productTags = [];
    if (type === 'category') {
      this.categoryFilter(name, index);
      // console.log('Cat loop', name, index, type);
      this.activeCategory = index;
    }

    if (type === 'publisher') {
      this.publisherFilter(name);
      // console.log('Publisher loop', name, index, type);
      this.activePublisher = index;
    }
    this.productPagination();
  }

  categoryFilter(name: any, index: any) {
    this.filteredData = [];
    this.filteredTags = [];
    if (name !== 'All') {
      this.unSortedProductedList.filter((product: any) => {
        if (product.product_category === index) {
          this.filteredData.push(product);
          product.product_tags.map((tags: any) => {
            this.filteredTags.push(tags);
          });
        }
      });
      this.productList = [...this.filteredData];
      this.productTags = [...this.filteredTags];
    }
    if (name === 'All') {
      this.productList = [...this.unSortedProductedList];
      this.productTags = [...this.unsortedProductTagsList];
    }
  }

  publisher_name: any;
  publisherFilter(name: any) {
    if (name.publisher_name === 'All') {
      this.publisher_name = name.publisher_name;
    } else {
      this.publisher_name = name.publisher_id;
    }
    // console.log('Publisher Filter', this.publisher_name);
    if (this.filteredData.length !== 0) {
      this.filteredData.filter((product: any) => {
        if (product.product_publisher === this.publisher_name) {
          this.productList.push(product);
          product.product_tags.map((tags: any) => {
            this.productTags.push(tags);
          });
        }
      });
      if (this.publisher_name === 'All') {
        this.productList = [...this.filteredData];
        this.productTags = [...this.filteredTags];
      }
    }

    if (this.filteredData.length === 0) {
      this.unSortedProductedList.filter((product: any) => {
        if (product.product_publisher === this.publisher_name) {
          this.productList.push(product);
          product.product_tags.map((tags: any) => {
            this.productTags.push(tags);
          });
        }
      });
      if (this.publisher_name === 'All') {
        this.productList = [...this.unSortedProductedList];
        this.productTags = [...this.unsortedProductTagsList];
      }
    }
  }

  publisherJson: any;
  getAllActivePublishers() {
    this.productService.getAllActivePublishers().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.publisherJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.publisherJson.status === 1) {
          this.publisher = [
            ...this.publisherJson.publishers,
            { publisher_name: 'All' },
          ];
          // this.publisherJson.publishers.map((publisher: any) => {
          //   this.publisher.push(publisher.publisher_name);
          // });
          // console.log(this.publisher);
          this.activePublisher = this.publisher.length - 1;
        }
      },
    });
  }
  // ================Publisher sorting end ==============
  //========== getproductTags start ===========
  productTagsResposne: any;
  productTags: any = [];
  tagsRendered: boolean = false;
  unsortedProductTagsList: any;
  getProductTags() {
    this.productService.getProductsTags().subscribe({
      next: (val: any) => {
        this.productTagsResposne = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.productTagsResposne.status === 1) {
          this.productTags = this.productTagsResposne.tags;
          this.unsortedProductTagsList = this.productTagsResposne.tags;
          this.updateTitle();
          if (this.productTags.length === 0) {
            this.tagsRendered = true;
          }
        }
      },
    });
  }
  //========== getproductTags End ===========
  // ====== tags related functionality start ===================

  searchtext: any = '';
  activeTag: any;
  paginate: any;
  searchByTags(tag: any, index: any) {
    this.searchtext = tag;
    this.activeTag = index;
    this.productPagination();
  }

  changed(text: any) {
    if (text === '' && this.searchtext === '') {
      this.activeTag = null;
    }
  }

  getrelatedProdcuts() {
    this.cartService.catType_value.subscribe({
      next: (val: any) => {
        this.searchtext = val;
      },
    });
    this.productPagination();
  }
  // =========tags related functionality end =================
  // ========= laod more functionality ===========
  productListCount: number = 20;
  loadMoreButton: boolean;
  loadMoreProducts(list_value: any, products: any) {
    this.productListCount += list_value;
    if (this.productListCount > this.productList?.length) {
      this.loadMoreButton = true;
    }
    // this.productPagination();
  }

  productPagination() {
    // console.log(this.productList);
    if (this.productList.length < 20) {
      this.loadMoreButton = true;
    } else if (this.productList?.length > 20) {
      this.loadMoreButton = false;
    }
  }

  // ================ meta tags ==========
  updateTitle() {
    this.title.setTitle('3D Models - Shop');
    this.meta.updateTag({
      name: 'keywords',
      content: this.productTags,
    });
    this.meta.updateTag({
      name: 'description',
      content:
        'Professional 3D models for VFX, game development, VR/AR, architecture,and animation. Review every mesh and texture before you buy. Over 16 different file formats and 3D model inspection available.',
    });

    this.meta.updateTag({
      property: 'og:title',
      content: '3D Models',
    });
    this.meta.updateTag({
      property: 'og:type',
      content: 'webiste',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Professional 3D models for VFX, game development, VR/AR, architecture,and animation. Review every mesh and texture before you buy. Over 16 different file formats and 3D model inspection available.',
    });
    this.meta.updateTag({
      property: 'og:image',
      content: '',
    });
    this.meta.updateTag({
      property: 'og:url',
      content: '',
    });
    // twitter tags almost all social media platforms
    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary',
    });
    this.meta.updateTag({
      name: 'twitter:site',
      content: '@immersionslabs',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'https://shop.immersionslabs.com/home',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: '3D Models',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      lang: 'en',
      content:
        'Professional 3D models for VFX, game development, VR/AR, architecture,and animation. Review every mesh and texture before you buy. Over 16 different file formats and 3D model inspection available.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: '',
    });
  }
}
