<!-- Login -->
<!--  py-24 -->
<section class="relative">
  <div class="lg:flex lg:h-full">
    <!-- Left -->
    <div class="relative lg:w-1/2 bg-grey">
      <div class="relative py-16 px-5 flex justify-center">
        <div class="w-50">
          <a
            routerLink="/cart"
            class="relative inline-block flex items-center py-16 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left mr-2"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
            <span class="text-white">Back</span>
          </a>
          <ng-container *ngIf="loaderContent === false; else amountContainer">
            <app-loader></app-loader>
          </ng-container>
          <ng-template #amountContainer>
            <span class="text-white">Final cart value</span>
            <h2
              class="font-display text-jacarta-700 mb-16 text-4xl font-medium text-white"
            >
              ₹{{ amount / 100 }}
            </h2>
            <!-- Products list-->
            <div
              class="border-jacarta-100 relative flex items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg mb-6"
              *ngFor="let products of products"
            >
              <div>
                <h3
                  class="font-display text-jacarta-700 mb-1 text-base font-semibold"
                >
                  {{ products.product_name }}
                </h3>
                <ng-container
                  *ngIf="
                    products.hasOwnProperty('sale_discount') &&
                      products.hasOwnProperty('sale_title');
                    else normalDiscount
                  "
                >
                  <span class="mb-1 text-green block text-sm">{{
                    products.sale_title
                  }}</span>
                  <span class="text-jacarta-500 block text-sm"
                    >Discount
                    {{
                      products.product_individual_discount +
                        products.sale_discount
                    }}%</span
                  >
                </ng-container>
                <ng-template #normalDiscount>
                  <span class="text-jacarta-500 mb-3 block text-sm"
                    >Discount {{ products.product_individual_discount }}%</span
                  >
                </ng-template>
              </div>
              <div class="ml-auto">
                <span class="text-jacarta-700 font-display"
                  >₹ {{ products.product_final_price }}</span
                >
                <span class="text-jacarta-300 block text-xs"
                  ><del>₹ {{ products.product_price }}</del></span
                >
              </div>
            </div>
            <div
              class="border-jacarta-100 relative flex items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg mb-6"
            >
              <div>
                <h3
                  class="font-display text-jacarta-700 mb-1 text-base font-semibold"
                >
                  GST (12%)
                </h3>
              </div>
              <div class="ml-auto">
                <span class="text-jacarta-700 font-display"
                  >₹ {{ gstValue }}</span
                >
              </div>
            </div>
          </ng-template>
          <div class="powered-by">
            <div
              class="text-center font-medium text-md flex justify-center text-white items-center"
            >
              powered by
              <img src="assets/img/stripe-icon.png" alt="" width="100px" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right -->
    <div class="relative items-center p-[6%] lg:w-1/2 bg-white">
      <div class="w-full text-center">
        <form id="payment-form">
          <div class="mx-auto">
            <div class="mb-6">
              <label
                for="userFirstName"
                class="font-display text-left text-jacarta-700 mb-2 block"
                >Name<span class="text-red">*</span></label
              >
              <input
                type="text"
                id="userFirstName"
                class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
                value="{{
                  user_data?.user_first_name + ' ' + user_data?.user_last_name
                }}"
                readonly
              />
            </div>

            <!-- Email -->
            <div class="mb-6">
              <label
                for="userEmail"
                class="font-display text-left text-jacarta-700 mb-2 block"
                >Email<span class="text-red">*</span></label
              >
              <input
                type="text"
                id="userEmail"
                class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
                value="{{ user_data?.user_email }}"
                readonly
              />
            </div>
            <div class="mb-6">
              <label
                for="card"
                class="font-display text-left text-jacarta-700 mb-2 block"
                >Credit or Debit Card</label
              >
              <div
                id="card-element"
                class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
              >
                <!--Stripe.js injects the Card Element-->
              </div>
              <div id="card-errors" role="alert"></div>
            </div>
            <div class="">
              <label
                for="addressLine"
                class="font-display text-left text-jacarta-700 mb-2 block"
                >Address<span class="text-red">*</span></label
              >
              <input
                type="text"
                placeholder="Enter Address *"
                class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
                id="addressLine"
                value=""
              />
            </div>
            <div id="line1-errors" class="mb-2" role="alert"></div>
            <div
              class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-2"
            >
              <!-- Password -->
              <div class="mb-6">
                <label
                  for="city"
                  class="font-display text-left text-jacarta-700 mb-2 block"
                  >City<span class="text-red">*</span></label
                >
                <input
                  type="text"
                  placeholder="Enter City *"
                  class="form-control"
                  id="city"
                  value=""
                  class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
                />
                <div id="city-errors" role="alert"></div>
              </div>
              <div class="mb-6">
                <label
                  for="city"
                  class="font-display text-left text-jacarta-700 mb-2 block"
                  >State<span class="text-red">*</span></label
                >
                <input
                  type="text"
                  placeholder="Enter State *"
                  class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
                  id="state"
                  value=""
                />
                <div id="state-errors" role="alert"></div>
              </div>
            </div>
            <div class="mb-6">
              <label
                for="country"
                class="font-display text-left text-jacarta-700 mb-2 block"
                >Country</label
              >
              <select
                class="border-jacarta-100 hover:ring-accent/10 focus:ring-accent w-full rounded-lg py-3 hover:ring-2"
                id="country"
                name="country"
                required
              >
                <option value="">select country</option>
                <option value="AF">Afghanistan</option>
                <option value="AX">Aland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Cote D'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curacao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and Mcdonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">
                  Korea, Democratic People's Republic of
                </option>
                <option value="KR">Korea, Republic of</option>
                <option value="XK">Kosovo</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory, Occupied</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthelemy</option>
                <option value="SH">Saint Helena</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="CS">Serbia and Montenegro</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option selected value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.s.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
            </div>
            <div id="country-errors" role="alert"></div>
            <!-- Submit -->
            <div class="mt-4">
              <button
                type="submit"
                [disabled]=""
                class="cursor-pointer bg-accent w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              >
                Pay now
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- end login -->
