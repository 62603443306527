import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from '../sharedServices/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private routes: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // console.log("Route Guards user data",state.url)
    const userData = this.authService.userInfo.getValue();
    // alert(JSON.stringify(userData.admin_id))
    if (userData && userData.user_id) {
      if (state.url.indexOf('login') > -1) {
        this.routes.navigate(['/profile']);
        return false;
      }
    } else {
      if (state.url.indexOf('profile') > -1) {
        this.routes.navigate(['/login', { queryParams: { page: state.url } }]);
        return false;
      }
    }

    return true;
  }
}
