<div class="lg:flex">
  <!-- Records -->
  <div class="mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pr-10">
    <div
      class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 relative flex flex-wrap items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg relative"
      *ngFor="let online of cardList"
    >
      <figure class="mr-5 self-start">
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '150px',
            height: '100px',
            'background-color': themeColor,
            'margin-right': '20px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
      </figure>
      <div class="mr-2">
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '350px',
            height: '5px',
            'background-color': themeColor,
            'margin-bottom': '10px',
            display: 'block',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '350px',
            height: '5px',
            'background-color': themeColor,
            display: 'block',
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '350px',
            height: '5px',
            'background-color': themeColor,
            display: 'block',
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <aside class="basis-4/12 lg:pl-5">
    <div
      class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 relative rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg relative"
    >
      <div class="flex items-center justify-between" *ngFor="let p of cardList">
        <ngx-skeleton-loader
          class="dark:border-jacarta-600"
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '5px',
            'background-color': themeColor,
            'margin-right': '20px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="dark:border-jacarta-600"
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '5px',
            'background-color': themeColor,
            'margin-right': '20px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </aside>
</div>
