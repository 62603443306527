import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';

import { ProductRoutingModule } from './product-routing.module';
import { SharedModule } from '../sharedModule/shared.module';

import { ProductListComponent } from './product-management/product-list/product-list.component';
import { ProductDetailComponent } from './product-management/product-detail/product-detail.component';
import { ProductDetailTabsComponent } from './product-management/product-detail-tabs/product-detail-tabs.component';
import { RelatedProoductsComponent } from './product-management/related-prooducts/related-prooducts.component';
import { PaymentCheckoutComponent } from './payment-management/payment-checkout/payment-checkout.component';
import { PaymentSuccessComponent } from './payment-management/payment-success/payment-success.component';

import { SaleListComponent } from './sale-management/sale-list/sale-list.component';
import { SaleDetailComponent } from './sale-management/sale-detail/sale-detail.component';
import { SaleCardComponent } from './sale-management/sale-card/sale-card.component';
import { SaleCountdownCardComponent } from './sale-management/sale-countdown-card/sale-countdown-card.component';

import { CartComponent } from './cart-management/cart/cart.component';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { SearchTagsFilterPipe } from '../pipes/search-tags.pipe';
import { environment } from 'src/environments/environment';
import { RazorPayComponent } from './payment-management/razor-pay/razor-pay.component';

@NgModule({
  declarations: [
    SearchFilterPipe,
    SearchTagsFilterPipe,
    ProductListComponent,
    ProductDetailComponent,
    SaleListComponent,
    SaleDetailComponent,
    ProductDetailTabsComponent,
    SaleCardComponent,
    RelatedProoductsComponent,
    CartComponent,
    SaleCountdownCardComponent,
    PaymentCheckoutComponent,
    PaymentSuccessComponent,
    RazorPayComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductRoutingModule,
    CarouselModule,
    SharedModule,
    NgxStripeModule.forRoot(environment.stripepublish_key),
  ],
  exports: [ProductListComponent, SaleListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ProductModule { }
