import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../sharedServices/authentication.service';
import { CartService } from '../sharedServices/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  cart_value: any;
  saleCount: number;
  constructor(
    private authService: AuthenticationService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isAuthenticated();
    this.cartService.cart_value.subscribe({
      next: (val: any) => {
        this.cart_value = val;
      },
    });

    this.cartService.saleCount.subscribe({
      next: (val: any) => {
        this.saleCount = val;
        // console.log(this.saleCount);
      },
    });

    let product_list = localStorage.getItem('products');
    let number = product_list ? JSON.parse(product_list) : [];
    this.cart_value = number.length;
  }

  loggedin: boolean = false;
  isAuthenticated() {
    this.loggedin = this.authService.isLoggedIn();
    // if (this.isAuthenticated) {
    //   this.getProfileData();
    // }
  }
  mobileClass: boolean = false;
  mobileMenu() {
    this.mobileClass = !this.mobileClass;
    if (this.mobileClass == true) {
      document.body.classList.add('nav-open-noscroll');
    } else {
      document.body.classList.remove('nav-open-noscroll');
    }
  }

  userLogout() {
    this.authService.userLogout();
  }

  navigateProductSection() {
    document.getElementById('products')?.scrollIntoView({ behavior: 'smooth' });
  }
  navigateSaleSection() {
    document.getElementById('sale')?.scrollIntoView({ behavior: 'smooth' });
  }
  navigateSale() {
    this.router.navigate(['/home'], { fragment: 'sale' });
  }
}
