<!-- Collections -->
<ng-container *ngIf="products?.length !== 0">
  <section class="relative">
    <h1
      class="font-display text-jacarta-700 py-16 text-xl font-medium dark:text-white"
    >
      Related Products by <span class="text-green">{{ categoryName }}</span>
    </h1>

    <!-- Grid -->
    <div class="grid grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-5">
      <article
        *ngFor="let products of products.slice(0, 9)"
        (click)="navigateToDetail(products)"
        class="cursor-pointer"
      >
        <app-product-card [products]="products"></app-product-card>
      </article>
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg h-full flex items-center justify-center transition-shadow text-center"
        >
          <a
            (click)="navigateToSort(productType)"
            class="cursor-pointer absolute top-1\/2 left-1\/2 font-display text-jacarta-700 hover:text-accent text-base dark:text-white"
          >
            View All
          </a>
        </div>
      </article>
    </div>
  </section>
</ng-container>
<!-- end collections -->
