import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
})
export class ProductCardComponent implements OnInit {
  @Input() products: any;
  cloudFrontURL: any;
  constructor(private router: Router) {
    this.cloudFrontURL = environment.cloudFrontUrl;
  }
  ngOnInit(): void {
    // console.log(this.products);
  }
  // ============= navigate to detail ==============
  navigateToDetail(products: any) {
    const productName = products.product_name
      .split(' ')
      .join('-')
      .toLowerCase();
    this.router.navigate(['/detail', products.product_id, productName]);
  }
}
