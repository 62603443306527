import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/sharedServices/cart.service';
import { SaleService } from 'src/app/sharedServices/sale.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sale-list',
  templateUrl: './sale-list.component.html',
})
export class SaleListComponent implements OnInit {
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  countDownTimer: any;
  cloudFrontURL: any;
  constructor(
    private saleService: SaleService,
    private router: Router,
    private cartService: CartService
  ) {
    this.cloudFrontURL = environment.cloudFrontUrl;
  }
  ngOnInit(): void {
    this.getSaleItems();
  }

  saleListJson: any;
  saleItems: any;
  // saleList: any = [];
  saleItemsExist: boolean = false;
  datarendering: boolean = false;
  getSaleItems() {
    this.saleService.getSaleItems().subscribe({
      next: (val: any) => {
        // console.log(val);
        this.saleListJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.saleListJson.status === 1) {
          if (this.saleListJson.count === 0) {
            this.saleItemsExist = true;
          } else {
            this.saleItems = this.saleListJson.saleData;
            this.cartService.getSaleCount(this.saleItems.length);
            this.datarendering = true;
          }
        }
      },
    });
  }
  // ============== getsale item end =====================

  // ======= Timer start ============
  // days: any;
  // hours: any;
  // minutes: any;
  // seconds: any;
  // countDownTimer: any;
  getTimer(saleItems: any) {
    this.saleItems.map((item: any) => {
      this.countDownTimer = new Date(item.sale_end_date).getTime();

      const x = setInterval(() => {
        var now = new Date().getTime();
        var distance = this.countDownTimer - now;
        item.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        item.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        item.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        item.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // console.log(item);
      });
    });
  }
  // ================ timer end =============
}
