import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  apiUrl: any;
  isLoggedin: boolean = false;
  userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  jwtHelper = new JwtHelperService();
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public toastr: ToastrService
  ) {
    this.apiUrl = environment.apiUrl;
    this.loadUserInfo();
  }

  getHeaders() {
    var a_token = localStorage.getItem('access_token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + a_token,
      },
    };
    return options;
  }

  loadUserInfo() {
    let userdata = this.userInfo.getValue();
    if (!userdata) {
      const accesstoken = localStorage.getItem('access_token');
      if (accesstoken) {
        userdata = this.jwtHelper.decodeToken(accesstoken);
        this.userInfo.next(userdata);
        this.isLoggedin = true;
      }
    }
  }

  getToken() {
    return localStorage.getItem('access_token') || '';
  }
  getrefreshToken() {
    return localStorage.getItem('refresh_token') || '';
  }

  userLogin(logindata: any): Observable<boolean> {
    console.log(logindata);
    return this.httpClient.post(this.apiUrl + 'user/signin', logindata).pipe(
      map((value: any) => {
        if (value) {
          // console.log(value);
          localStorage.setItem('access_token', value.auth_token);
          localStorage.setItem('refresh_token', value.refresh_token);
          const decrypteduser = this.jwtHelper.decodeToken(value.auth_token);
          localStorage.setItem('expiration', decrypteduser.exp);

          this.userInfo.next(decrypteduser);
          this.isLoggedin = true;
          if (value.user_verified == 0) {
            this.toastr.error('Please Verify your Mail');
          }
          return true;
        }
        return false;
      })
    );
  }

  isLoggedIn() {
    if (localStorage.getItem('access_token') === null) {
      this.isLoggedin = false;
      return this.isLoggedin;
    } else {
      return true;
    }
  }

  userLogout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('user-products');
    window.location.reload();
    this.router.navigateByUrl('/home');
  }

  userefreshToken() {
    const options = this.getHeaders();
    const body = { refresh_token: this.getrefreshToken() };

    return this.httpClient.post(this.apiUrl + 'user/gentoken', body, options);
  }

  userRegistration(registerPayload: any) {
    return this.httpClient.put(this.apiUrl + 'user/signup', registerPayload);
  }

  userforgotPassword(email: any) {
    return this.httpClient.post(this.apiUrl + 'user/forgotpassword', email);
  }

  userResetPassword(resetPayload: any) {
    return this.httpClient.post(
      this.apiUrl + 'user/resetpassword',
      resetPayload
    );
  }
}
