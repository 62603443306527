<div class="md:flex md:flex-wrap">
  <!-- Image -->
  <figure
    class="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2"
  >
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'border-radius': '12px',
        width: '100%',
        height: '350px',
        'background-color': themeColor,
        'margin-right': '12px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    ></ngx-skeleton-loader>
  </figure>

  <!-- Details -->
  <div class="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
    <div class="mb-3 flex">
      <!-- Collection -->
      <div class="flex items-center">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '10px',
            'background-color': themeColor,
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        ></ngx-skeleton-loader>
      </div>

      <!-- Likes / Actions -->
      <div class="ml-auto flex space-x-2">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '10px',
            'background-color': themeColor,
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        ></ngx-skeleton-loader>

        <!-- Actions -->
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '10px',
            'background-color': themeColor,
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'border-radius': '12px',
        width: '100%',
        height: '10px',
        'background-color': themeColor,
        'margin-bottom': '10px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'border-radius': '12px',
        width: '100%',
        height: '10px',
        'background-color': themeColor,
        'margin-bottom': '10px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    ></ngx-skeleton-loader>

    <div class="flex flex-wrap">
      <div *ngFor="let tag of tags" class="mr-2">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '5px',
            'background-color': themeColor,
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'border-radius': '12px',
        width: '100%',
        height: '5px',
        'background-color': themeColor,
        'margin-bottom': '10px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'border-radius': '12px',
        width: '100%',
        height: '5px',
        'background-color': themeColor,
        'margin-bottom': '10px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'border-radius': '12px',
        width: '100%',
        height: '5px',
        'background-color': themeColor,
        'margin-bottom': '10px',
        'animation-duration': '2s',
        padding: '15px'
      }"
    ></ngx-skeleton-loader>
    <div class="flex flex-wrap">
      <div *ngFor="let tag of tags" class="mr-2">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'border-radius': '12px',
            width: '100px',
            height: '5px',
            'background-color': themeColor,
            'margin-bottom': '10px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <!-- end details -->
</div>
