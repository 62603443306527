<!-- Page title -->
<section class="relative pt-24 lg:pb-96">
  <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
    <img src="img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
  </picture>
  <div class="container">
    <!-- Page Title -->
    <div class="mx-auto max-w-2xl py-16 text-center">
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'border-radius': '12px',
          width: '250px',
          height: '5px',
          'background-color': themeColor,
          'margin-right': '20px',
          'animation-duration': '2s',
          padding: '15px'
        }"
      >
      </ngx-skeleton-loader>
      <p class="dark:text-jacarta-300 text-lg leading-normal">
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '100%',
            height: '2px',
            'background-color': themeColor,
            'margin-right': '20px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          [count]="1"
          [theme]="{
            'border-radius': '12px',
            width: '100%',
            height: '2px',
            'background-color': themeColor,
            'margin-right': '20px',
            'animation-duration': '2s',
            padding: '15px'
          }"
        >
        </ngx-skeleton-loader>
      </p>
    </div>
  </div>
</section>

<!-- Intro / Statistics -->
<section class="pb-24">
  <div class="container">
    <!-- Video Lightbox -->
    <figure
      class="before:bg-jacarta-900/25 relative mt-16 overflow-hidden rounded-3xl before:absolute before:inset-0 lg:-mt-96"
    >
      <ngx-skeleton-loader
        [count]="1"
        [theme]="{
          'border-radius': '12px',
          width: '100%',
          height: '650px',
          'background-color': themeColor,
          'margin-right': '20px',
          'animation-duration': '2s',
          padding: '15px'
        }"
      >
      </ngx-skeleton-loader>
    </figure>

    <!-- Statistics -->
    <div class="pt-24">
      <div class="grid grid-cols-2 md:grid-cols-4">
        <article *ngFor="let x of cardList">
          <ngx-skeleton-loader
            [count]="1"
            [theme]="{
              'border-radius': '20px',
              width: '250px',
              height: '20px',
              'background-color': themeColor,
              'margin-right': '20px',
              'margin-bottom': '5px',
              'animation-duration': '2s',
              padding: '5px'
            }"
          >
          </ngx-skeleton-loader>
          <ngx-skeleton-loader
            [count]="1"
            [theme]="{
              'border-radius': '20px',
              width: '250px',
              height: '30px',
              'background-color': themeColor,
              'margin-right': '20px',
              'animation-duration': '2s',
              padding: '5px'
            }"
          >
          </ngx-skeleton-loader>
        </article>
      </div>
    </div>
  </div>
</section>
<!-- end intro / statistics -->
