import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// @ts-ignore
import crypto from 'crypto-browserify';

@Injectable({ providedIn: 'root' })
export class ProductService {
  apiURL: any;
  iv: any;
  key: any;
  Algorithm: any;
  constructor(private httpClient: HttpClient) {
    this.apiURL = environment.apiUrl;
    this.iv = environment.iv;
    this.key = environment.key;
    this.Algorithm = environment.Algorithm;
  }
  getHeaders() {
    var a_token = localStorage.getItem('access_token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + a_token,
      },
    };
    return options;
  }

  // ========== get all products =================
  getAllProducts(sortType: any) {
    return this.httpClient.get(this.apiURL + 'shop/sort/' + sortType);
  }

  // ========== get all Sale products =================
  getSaleAllProducts() {
    return this.httpClient.get(this.apiURL + 'shop/saleProducts');
  }

  // ========== get  product by id =================
  getProductByid(product_id: any) {
    return this.httpClient.get(this.apiURL + 'shop/product/' + product_id);
  }

  // ========== get  productstags =================
  getProductsTags() {
    return this.httpClient.get(this.apiURL + 'shop/productTags');
  }

  // ========== get  purchased products =================
  getPurchasedProducts() {
    const options = this.getHeaders();
    return this.httpClient.get(this.apiURL + 'shop/purchase/', options);
  }

  // ========== get  transactions =================
  getUserTransactions() {
    return this.httpClient.get(this.apiURL + 'shop/transactions/');
  }

  // ========== get refund transactions =================
  getUserRefundTransactions() {
    return this.httpClient.get(this.apiURL + 'shop/transactions/status/refund');
  }

  // ============ addinglike ===================
  addingLike(product_id: any, like_type: any) {
    const body = {
      product_id: product_id,
      product_likes: Number(like_type),
    };
    return this.httpClient.post(this.apiURL + 'shop/addlikes', body);
  }
  // ============ addingRating ===================
  addRating(product_id: any, product_rating: any) {
    const body = {
      product_id: product_id,
      product_rating: Number(product_rating),
    };
    return this.httpClient.post(this.apiURL + 'shop/addrating', body);
  }
  // ============ adding Transactions ===================
  addTransaction(payload: any) {
    const body = {
      transaction_for: payload,
    };
    let biv = Buffer.from(this.iv, 'hex');
    let bkey = Buffer.from(this.key, 'hex');
    const cipher = crypto.createCipheriv(this.Algorithm, bkey, biv);
    let body_hash = cipher.update(JSON.stringify(body), 'utf8', 'hex');
    body_hash += cipher.final('hex');
    const options = this.getHeaders();
    return this.httpClient.put(
      this.apiURL + 'shop/purchase/razorpay/' + body_hash,
      body,
      options
    );
  }

  // Get all publishers
  getAllActivePublishers() {
    return this.httpClient.get(this.apiURL + 'shop/activePublishers');
  }
}
