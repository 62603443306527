import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-card-skelton',
  templateUrl: './product-card-skelton.component.html',
  styles: [],
})
export class ProductCardSkeltonComponent implements OnInit {
  cardList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  themeColor: any;
  constructor() {
    if (localStorage.getItem('theme-preference') === 'dark') {
      this.themeColor = '#131740';
    } else if (localStorage.getItem('theme-preference') === 'light') {
      this.themeColor = '#e7e8ec';
    }
  }

  ngOnInit(): void {}
}
