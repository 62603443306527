import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchProductFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any, searchtext: string): any[] {
    if (!items) return [];
    if (!searchtext) return items;

    return items.filter((item: any) => {
      if (item.product_tags.includes(searchtext)) {
        return item.product_tags.includes(searchtext);
      } else if (item.product_name.toLowerCase().includes(searchtext)) {
        return item.product_name
          .toLowerCase()
          .includes(searchtext.toLowerCase());
      }
    });
  }
}
