import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductDetailComponent } from './product-management/product-detail/product-detail.component';
import { SaleDetailComponent } from './sale-management/sale-detail/sale-detail.component';
import { SaleListComponent } from './sale-management/sale-list/sale-list.component';
import { CartComponent } from './cart-management/cart/cart.component';
import { PaymentSuccessComponent } from './payment-management/payment-success/payment-success.component';
import { PaymentCheckoutComponent } from './payment-management/payment-checkout/payment-checkout.component';

const routes: Routes = [
  {
    path: 'detail/:id/:title',
    component: ProductDetailComponent,
  },
  {
    path: 'sale-list',
    component: SaleListComponent,
  },
  {
    path: 'sale-detail/:id',
    component: SaleDetailComponent,
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'payment-status',
    component: PaymentSuccessComponent,
  },
  {
    path: 'payment-status/:id',
    component: PaymentSuccessComponent,
  },
  {
    path: 'payment-checkout',
    component: PaymentCheckoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductRoutingModule { }
