import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// @ts-ignore
import crypto from 'crypto-browserify';
import { Buffer } from 'buffer';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';
import { CartService } from 'src/app/sharedServices/cart.service';
import { ProductService } from 'src/app/sharedServices/product.service';
import { Title } from '@angular/platform-browser';
import { UserManagementService } from 'src/app/sharedServices/user-management.service';
declare var Razorpay: any;
declare var $: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
})
export class CartComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;
  selectedProducts: any;
  userProducts: any;
  nodata_container: boolean = false;
  agree!: boolean;
  totalValue: number;
  finalPrice: number;
  gstValue: number = 0;

  // Crypto
  iv: any;
  key: any;
  Algorithm: any;
  cloudFrontURL: any;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private authService: AuthenticationService,
    private router: Router,
    public toastr: ToastrService,
    private title: Title,
    private userManagementService: UserManagementService
  ) {
    this.isAuthenticated();
    this.iv = environment.iv;
    this.key = environment.key;
    this.Algorithm = environment.Algorithm;
    this.cloudFrontURL = environment.cloudFrontUrl;
    if (localStorage.getItem('theme-preference') === 'dark') {
      document
        .getElementsByTagName('body')[0]
        .classList.add('dark:bg-jacarta-900');
    }
  }
  ngOnInit(): void {
    let localstorageIDs = localStorage.getItem('products');
    this.selectedProducts = localstorageIDs ? JSON.parse(localstorageIDs) : [];

    this.cartedProducts();
  }

  cartedProducts() {
    this.onlineProducts = [];
    this.offlineProducts = [];
    this.allProducts = [];
    this.finalPrice = 0;
    this.totalValue = 0;
    // console.log('CARTED FUNCTION', this.totalValue);
    if (this.selectedProducts.length == 0) {
      this.nodata_container = true;
    }
    this.selectedProducts.map((productObj: any) => {
      this.getProductByid(productObj);
    });
    this.updateTitle();
  }

  // ================ get Product data start ===============
  productJson: any;
  productData: any;
  dataRendered: boolean = false;
  allProducts: any = [];
  onlineProducts: any = [];
  offlineProducts: any = [];

  getProductByid(productObj: any) {
    this.productService.getProductByid(productObj.product_id).subscribe({
      next: (val: any) => {
        this.productJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.productJson.status === 1) {
          this.productData = this.productJson.product;
          // this.productData.is_cart = false;
          // console.log('Before', this.productData);
          if (this.productData.product_sale.length > 0) {
            this.productData.prodcut_sale_data.map((saleData: any) => {
              if (productObj.hasOwnProperty('sale_id') === true) {
                if (saleData.sale_id === productObj.sale_id) {
                  saleData.selected = true;
                  this.productData.selected_sale_discount =
                    saleData.sale_discount;
                  this.productData.regular = false;
                }
              } else {
                this.productData.regular = true;
              }
            });
          } else {
            this.productData.regular = true;
          }

          if (this.productData.product_type === 0) {
            this.onlineProducts.push(this.productData);
          } else if (this.productData.product_type === 1) {
            this.offlineProducts.push(this.productData);
          }
          this.allProducts.push(this.productData);
          let cost = 0;
          this.allProducts.map((products: any) => {
            if (products.hasOwnProperty('selected_sale_discount') === true) {
              this.finalPrice =
                products.product_price -
                (products.product_price *
                  (products.product_individual_discount +
                    products.selected_sale_discount)) /
                  100;
            } else {
              this.finalPrice =
                products.product_price -
                (products.product_price *
                  products.product_individual_discount) /
                  100;
            }
            cost += this.finalPrice;
          });
          this.dataRendered = true;
          if (this.selectedProducts.length === this.allProducts.length) {
            this.gstValue = (cost * 12) / 100;
            this.totalValue = cost;
          }
        }
      },
    });
  }

  // ===================Get product Data end ===================

  // ============ Remove From Cart ============
  removeFromCart(selectedProduct: any) {
    let arr = this.selectedProducts.filter(
      (item: any) => item.product_id !== selectedProduct.product_id
    );
    localStorage.setItem('products', JSON.stringify(arr));
    this.getProducts();
    this.cartService.getCartValue(this.selectedProducts.length);
    this.dataRendered = false;
    this.cartedProducts();
  }

  getProducts() {
    let localCourses = localStorage.getItem('products');
    this.selectedProducts = localCourses ? JSON.parse(localCourses) : [];
  }

  // =============== Remoove From Cart end ===============
  loggedin: boolean = false;
  isAuthenticated() {
    this.loggedin = this.authService.isLoggedIn();
    if (this.loggedin === true) {
      let localstorageuserIDs = localStorage.getItem('user-products');
      this.userProducts = localstorageuserIDs
        ? JSON.parse(localstorageuserIDs)
        : [];
      // console.log('user products', this.userProducts.length);
      if (this.userProducts.length > 0) {
        this.userProducts.map((product_id: any) => {
          if (this.productData) {
            if (product_id === this.productData.product_id) {
              this.productData.purchased = true;
            }
          }
        });
      }
    }
  }
  // ===========loggedin User Data=============
  profileJsonData: any;
  profileData: any;
  getLoggedinUser() {
    this.userManagementService.getLoggedinUser().subscribe({
      next: (val: any) => {
        // console.log('profile Data', val);
        this.profileJsonData = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.profileJsonData.status === 1) {
          this.profileData = this.profileJsonData.user;
        }
      },
    });
  }

  // ================  Switch Sale Start ===========
  sale_id: any;

  switchSale(sale_data: any, product_data: any) {
    this.finalPrice = 0;
    this.totalValue = 0;
    this.getProducts();
    if (sale_data === 'regular') {
      this.selectedProducts.map((products: any) => {
        if (products.product_id === product_data?.product_id) {
          if (products.hasOwnProperty('sale_id') === true) {
            delete products.sale_id;
          }
        }
      });
    } else if (sale_data.sale_id) {
      console.log(sale_data);
      this.sale_id = sale_data.sale_id;
      this.selectedProducts.map((prodcuts: any) => {
        if (
          prodcuts.hasOwnProperty('sale_id') === true &&
          prodcuts.sale_id !== sale_data.sale_id
        ) {
          console.log(sale_data.sale_id);
          prodcuts['sale_id'] = sale_data.sale_id;
        } else {
          console.log(product_data);
          if (product_data.product_id === prodcuts.product_id) {
            prodcuts.sale_id = sale_data.sale_id;
          }
        }
      });
    }
    localStorage.setItem('products', JSON.stringify(this.selectedProducts));
    this.dataRendered = false;
    this.ngOnInit();
  }
  // ================  Switch Sale End ===========

  // =========== Purchase navigation =================
  cartObject: any = {
    cart: [],
  };
  stripeCheckout() {
    this.cartObject.cart = [...this.selectedProducts];
    if (this.loggedin === true) {
      this.transaction(this.cartObject);
      this.getLoggedinUser();
    }
  }

  transaction_json: any;
  client_secret_hash: any;
  transaction(cartObject: any) {
    this.productService.addTransaction(cartObject).subscribe({
      next: (val: any) => {
        this.transaction_json = val;
        // console.log('Transaction', this.transaction_json);
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.transaction_json.status == 1) {
          // this.toastr.success(this.transaction_json.message);
          // this.client_secret_hash = this.transaction_json.hash;
          // localStorage.setItem('sc', this.client_secret_hash);
          // document.getElementById('closeModal')?.click();
          // this.router.navigate(['/payment-checkout']);
          this.closebutton.nativeElement.click();
          this.makePayment(this.transaction_json.order_id);
        }
      },
    });
  }

  // ============= navigate to detail ==============
  navigateToDetail(products: any) {
    const productName = products.product_name.split(' ').join('-');
    this.router.navigate(['/detail', productName, products.product_id]);
  }
  // ========= navigate to detail end ==============
  // =========== MEta tags start ===================
  updateTitle() {
    this.title.setTitle('Cart | Shopforar');
  }
  // =========== meta tags end ====================

  // ================= RazorPay Start ============
  async makePayment(order_id: any) {
    const options = {
      key: 'rzp_test_PHQ1J2R06k3lV5',
      // amount: this.totalAmount, // amount in paisa
      currency: 'INR',
      name: 'ShopforAR',
      description: 'Test payment',
      image: 'https://sbsite.shopforar.com/assets/img/black_logo.png',
      order_id: order_id,
      prefill: {
        name:
          this.profileData?.user_first_name + this.profileData?.user_last_name,
        email: this.profileData?.user_email,
        contact: this.profileData?.user_phone,
      },
      notes: {
        address: 'Razorpay Corporate ',
      },
      theme: {
        color: '#004d40',
      },
      handler: {
        // alert
      },
      created_at: new Date(),
    };
    options.handler = (response: any) => {
      // console.log(response);
      this.razorpayResponse(response);
    };

    const razorpay = new Razorpay(options);
    razorpay.open();
  }

  razorpayResponse(razorpayresponse: any) {
    if (razorpayresponse.razorpay_invoice_status === 'paid') {
      this.router.navigate(['/payment-status']);
    }

    //   {
    //     "razorpay_payment_id": "pay_OLh8SWLotq5WKP",
    //     "razorpay_invoice_id": "inv_OLh8LZI6jMNnvn",
    //     "razorpay_invoice_status": "paid",
    //     "razorpay_invoice_receipt": null,
    //     "razorpay_signature": "29460c0f78d7b8578e598ab43e3166249e8126526e704c6cb87e77a5b9f9c7ca"
    // }
  }
}
