import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  apiURL: any;
  cart_value = new Subject();
  catType_value = new Subject();
  saleCount = new Subject();
  constructor(private httpClient: HttpClient) {}
  getCartValue(value: any) {
    this.cart_value.next(value);
  }

  getSaleCount(value: any) {
    this.saleCount.next(value);
  }
}
