<!-- Tabs -->
<div class="scrollbar-custom mt-14 overflow-x-auto rounded-lg">
  <div class="min-w-fit">
    <!-- Tabs Nav -->
    <ul class="nav nav-tabs flex items-center mb-8" role="tablist">
      <!-- details -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
          id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab"
          aria-controls="details" aria-selected="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="mr-1 h-5 w-5 fill-current">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
          </svg>
          <span class="font-display text-base font-medium">Details</span>
        </button>
      </li>

      <!-- Properties -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
          id="properties-tab" data-bs-toggle="tab" data-bs-target="#properties" type="button" role="tab"
          aria-controls="properties" aria-selected="false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="mr-1 h-5 w-5 fill-current">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17z" />
          </svg>
          <span class="font-display text-base font-medium">Properties</span>
        </button>
      </li>

      <!-- Files -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
          id="files-tab" data-bs-toggle="tab" data-bs-target="#files" type="button" role="tab" aria-controls="files"
          aria-selected="false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="mr-1 h-5 w-5 fill-current">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0 6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z" />
          </svg>
          <span class="font-display text-base font-medium">Files</span>
        </button>
      </li>

      <!-- Features -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
          id="features-tab" data-bs-toggle="tab" data-bs-target="#features" type="button" role="tab"
          aria-controls="features" aria-selected="false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="mr-1 h-5 w-5 fill-current">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M11.95 7.95l-1.414 1.414L8 6.828 8 20H6V6.828L3.465 9.364 2.05 7.95 7 3l4.95 4.95zm10 8.1L17 21l-4.95-4.95 1.414-1.414 2.537 2.536L16 4h2v13.172l2.536-2.536 1.414 1.414z" />
          </svg>
          <span class="font-display text-base font-medium">Features</span>
        </button>
      </li>

      <!-- License -->
      <li class="nav-item" role="presentation">
        <button
          class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
          id="license-tab" data-bs-toggle="tab" data-bs-target="#license" type="button" role="tab"
          aria-controls="license" aria-selected="false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="mr-1 h-5 w-5 fill-current">
            <path fill="none" d="M0 0H24V24H0z" />
            <path
              d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z" />
          </svg>
          <span class="font-display text-base font-medium">License</span>
        </button>
      </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content">
      <!-- details -->
      <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
        <div
          class="p-6 md:p-10 dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 grid w-full rounded-lg rounded-tl-none border bg-white text-sm dark:text-white">
          <div class="dark:text-jacarta-300 mb-6 text-md" [innerHTML]="productDataTabs?.product_sub_description"></div>
          <div class="mb-2 flex items-center">
            <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">Language:</span>
            <span class="text-green">
              &nbsp;{{ productDataTabs?.product_language }}</span>
          </div>
          <div class="mb-2 flex items-center">
            <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">Product Publisher:</span>
            <a class="text-green cursor-pointer" (click)="navigateToSection('publisher')">
              &nbsp;{{ productDataTabs?.product_publisher }}</a>
          </div>
          <div class="mb-2 flex items-center">
            <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">Product Category:</span>

            <span class="text-green cursor-pointer" *ngFor="let cat of categories; let i = index">
              <a *ngIf="i === productDataTabs?.product_type" (click)="navigateToSection('categories')"
                class="cursor-pointer text-green">{{ cat }}
              </a>
            </span>
          </div>
        </div>
      </div>

      <!-- Properties -->
      <div class="tab-pane fade" id="properties" role="tabpanel" aria-labelledby="properties-tab">
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
          <div class="grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4">
            <div
              class="dark:bg-jacarta-800 dark:border-jacarta-600 bg-light-base rounded-2lg border-jacarta-100 flex flex-col space-y-2 border p-5 text-center transition-shadow hover:shadow-lg"
              *ngFor="let props of productDataTabs?.product_file_description">
              <span class="text-green text-sm uppercase">{{
                props.keyName
                }}</span>
              <span class="text-jacarta-700 text-base dark:text-white">{{
                props.keyValue
                }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Files -->
      <div class="tab-pane fade" id="files" role="tabpanel" aria-labelledby="files-tab">
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
          <div class="mb-6 flex items-center">
            <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">Product File:</span>
            <div class="flex space-y-2 text-center">
              <div class="flex flex-wrap items-center">
                <div class="text-jacarta-700 text-base dark:text-white mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-file-zip" viewBox="0 0 16 16">
                    <path
                      d="M6.5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599zm2 0h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243z" />
                    <path
                      d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm5.5-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9v1H8v1h1v1H8v1h1v1H7.5V5h-1V4h1V3h-1V2h1z" />
                  </svg>
                </div>
                <ng-container *ngIf="
                    productDataTabs.hasOwnProperty('purchased') &&
                    isAuthenticated()
                  ">
                  <a href="#"
                    class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded py-2 px-8 text-center font-semibold text-white transition-all">Send
                    Download Link ot email</a>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="flex flex-wrap items-center">
              <div
                class="dark:bg-jacarta-800 dark:border-jacarta-600 bg-light-base rounded-2lg border-jacarta-100 flex flex-col space-y-2 justify-center border p-5 text-center transition-shadow hover:shadow-lg mr-3 relative mb-6"
                *ngFor="
                  let props of productDataTabs?.product_files_list;
                  let i = index
                ">
                <span class="text-jacarta-700 text-base dark:text-white"><svg
                    class="w-6 h-6 mx-auto text-gray-800 dark:text-white" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                      d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
                <span class="text-green text-sm uppercase">{{
                  props.keyName
                  }}</span>
                <span
                  class="dark:border-jacarta-600 bg-green absolute right-0 text-white font-semibold -top-18 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white">{{
                  props.keyValue }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Features -->
      <div class="tab-pane fade" id="features" role="tabpanel" aria-labelledby="features-tab">
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
          <div class="grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4">
            <div
              class="dark:bg-jacarta-800 dark:border-jacarta-600 bg-light-base rounded-2lg border-jacarta-100 flex flex-col space-y-2 border p-5 text-center transition-shadow hover:shadow-lg"
              *ngFor="let feature of productDataTabs?.product_features">
              <span class="text-green text-sm uppercase">{{
                feature.title
                }}</span>
              <span class="text-jacarta-700 text-base dark:text-white">{{
                feature.description
                }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Licence -->
      <div class="tab-pane fade" id="license" role="tabpanel" aria-labelledby="license-tab">
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6">
          <div class="mb-2 flex items-center">
            <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">License:</span>
            <span class="text-jacarta-700 text-green" *ngIf="productDataTabs?.product_license == 0">Yes</span>
            <span class="text-jacarta-700 text-green" *ngIf="productDataTabs?.product_license == 1">No (This is a Free
              License)</span>
          </div>
          <div class="flex items-center" *ngIf="productDataTabs?.product_license == 0">
            <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">License Files:</span>
            <span class="text-jacarta-700 dark:text-white">
              <span *ngFor="let licence of productDataTabs?.product_license_files">{{ licence }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- end tab content -->
  </div>
</div>
<!-- end tabs -->