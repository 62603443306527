import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CartService } from 'src/app/sharedServices/cart.service';

@Component({
  selector: 'app-related-prooducts',
  templateUrl: './related-prooducts.component.html',
})
export class RelatedProoductsComponent implements OnInit {
  @Input() products: any;
  @Input() productType: any;
  categoryName: any;

  constructor(private router: Router, private cartService: CartService) {}
  ngOnInit(): void {
    // console.log('product Type', this.productType);
    if (
      this.productType == 0 ||
      this.productType == 1 ||
      this.productType == 2 ||
      this.productType == 3 ||
      this.productType == 4
    ) {
      switch (this.productType) {
        case 0:
          this.categoryName = '3DModel';
          break;
        case 1:
          this.categoryName = 'Books';
          break;
        case 2:
          this.categoryName = 'Posters';
          break;
        case 3:
          this.categoryName = 'Coasters';
          break;
        case 4:
          this.categoryName = 'Stickers';
          break;
      }
    } else if (this.productType.length !== 0) {
      this.productType.map((productType: any) => {
        this.categoryName = productType.publisher_name;
      });
    }
    // console.log(this.products);
  }
  navigateToSort(categoryName: any) {
    console.log(categoryName);
    if (
      categoryName === 0 ||
      categoryName === 1 ||
      categoryName === 2 ||
      categoryName === 3 ||
      categoryName === 4
    ) {
      let category;
      switch (categoryName) {
        case 0:
          category = '3DModel';
          break;
        case 1:
          category = 'Books';
          break;
        case 2:
          this.categoryName = 'Posters';
          break;
        case 3:
          this.categoryName = 'Coasters';
          break;
        case 4:
          this.categoryName = 'Stickers';
          break;
      }
      this.router.navigate(['home', categoryName]);
    } else {
      if (categoryName.length !== 0) {
        categoryName.map((publisher: any) => {
          this.router.navigate(['home', publisher.publisher_id]);
        });
      }
    }

    // // this.cartService.getValue(categoryName);
    // if (categoryName === 'Immersion Software Labs Pvt.Ltd.') {
    // } else {
    // }
  }
  // ============= navigate to detail ==============
  navigateToDetail(products: any) {
    const productName = products.product_name
      .split(' ')
      .join('-')
      .toLowerCase();
    this.router.navigate(['/detail', productName, products.product_id]);
  }
}
