import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WebsiteService {
  apiUrl: any;
  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getFooterData() {
    return this.httpClient.get(this.apiUrl + 'shop/footer');
  }
}
