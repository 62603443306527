export const environment = {
  production: false,
  apiUrl: 'https://sbbackend.immersionslabs.com/',
  // apiUrl: 'http://localhost:3000/',
  cloudFrontUrl: 'https://objects.immersionslabs.com/',
  studioWebsiteURL: 'https://sbar.immersionslabs.com/',
  projectURL: 'https://sbshop.immersionslabs.com/',

  Algorithm: 'aes-256-cbc', //Using AES encryption
  key: 'e0c0bf34d40469852fa457e63475fecdf93fca8c4e0de9577531ee0c77b6454b',
  iv: '35b8591b72dc0f404528a659e00a5fb0',

  // stripeSecret_key:
  //   'sk_test_51Ho5sTCr2NOssvr1EN0xBbcwUm5VHNwzkvcsyLo1tF1AlNpYAFxmB2CmM9az13JaXqxd2Is8VLAa55puGeL20rSo00LM8fcNc9',
  // stripepublish_key:
  //   'pk_test_51Ho5sTCr2NOssvr1BGH1aY5EPtvG7hp8suMdkU4UGkfxIe60ORg2g1gR34gjt8CoUX9hICL4p9cpSfZprmoYrTKk000B5LG1Ye',
  stripeSecret_key:
    'sk_test_51PHJYbSJ1KYPJYxrmoQaylD3S5q4m2mcLjebl8Q0xHHq5pNSxK8QpgL2VT7cHwanh6de8SeMkSzznSWSiGHGZxHU002eZ8drhx',
  stripepublish_key:
    'pk_test_51PHJYbSJ1KYPJYxrYJ1owN5y2URaEYQCTKFdlqMh2xfllsXUelHitENtRisoNWfDTV6HfXpehWe9oWnCbNZknOhj009ALNWp3q',

  cloudfrontURL: 'https://objects.immersionslabs.com/',
};
