<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let sale of productData?.prodcut_sale_data; let i = index">
    <ng-template carouselSlide>
      <div class="rounded-[1.25rem] mb-6 bg-light-base px-6 py-8 shadow-sm dark:bg-jacarta-700 md:px-16 lg:px-24">
        <div class="flex-wrap justify-between items-center lg:flex">
          <div class="mb-14 text-center w-1\/3">
            <figure class="relative">
              <img alt="image" loading="lazy" class="rounded-2.5xl sale-static-image" src="assets/img/sale.png" />
            </figure>
          </div>
          <div class="mb-14 w-1\/3">
            <h2 class="mb-4 font-display text-3xl text-jacarta-700 dark:text-white">
              {{ sale.sale_title }}
              <span class="text-accent">Alive!</span>
            </h2>
            <p class="mb-4 text-lg text-jacarta-500 dark:text-jacarta-300">
              Discount Tier: {{ sale.sale_discount }}%
            </p>
            <p class="mb-8 text-lg text-jacarta-500 dark:text-jacarta-300">
              You can buy with this product:
              <span class="text-green">₹{{
                productData?.product_price -
                (productData?.product_price *
                (productData?.product_individual_discount +
                sale.sale_discount)) /
                100
                }}</span>
            </p>
          </div>
          <div class="w-1\/3">
            <div class="js-countdown-single-timer mb-10 text-center md:space-x-4" data-countdown="2023-12-07T19:40:30"
              data-expired="This auction has ended">
              <ng-container *ngIf="sale.hasOwnProperty('sale_end'); else countdownTimer">
                <h4 class="mb-4 font-display text-3xl text-jacarta-700 dark:text-white">
                  Sale Ended
                </h4>
              </ng-container>
              <ng-template #countdownTimer>
                <h4 class="mb-4 font-display text-3xl text-jacarta-700 dark:text-white">
                  Sale Ends in
                </h4>
                <div class="flex justify-center flex-wrap">
                  <span
                    class="countdown-days mr-2 flex h-[100px] w-[100px] flex-col justify-center rounded-2lg border border-jacarta-100 bg-white text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"><span
                      class="js-countdown-days-number font-display text-xl font-medium md:text-3xl">{{ sale.days
                      }}</span><span
                      class="text-md tracking-tight text-jacarta-500 dark:text-jacarta-300">Days</span></span><span
                    class="countdown-hours mr-2 flex h-[100px] w-[100px] flex-col justify-center rounded-2lg border border-jacarta-100 bg-white text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"><span
                      class="js-countdown-hours-number font-display text-xl font-medium md:text-3xl">{{ sale.hours
                      }}</span><span
                      class="text-md tracking-tight text-jacarta-500 dark:text-jacarta-300">Hrs</span></span><span
                    class="countdown-minutes mr-2 flex h-[100px] w-[100px] flex-col justify-center rounded-2lg border border-jacarta-100 bg-white text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"><span
                      class="js-countdown-minutes-number font-display text-xl font-medium md:text-3xl">{{ sale.minutes
                      }}</span><span
                      class="text-md tracking-tight text-jacarta-500 dark:text-jacarta-300">Min</span></span><span
                    class="countdown-seconds mr-2 flex h-[100px] w-[100px] flex-col justify-center rounded-2lg border border-jacarta-100 bg-white text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"><span
                      class="js-countdown-seconds-number font-display text-xl font-medium md:text-3xl">{{ sale.seconds
                      }}</span><span
                      class="text-md tracking-tight text-jacarta-500 dark:text-jacarta-300">Sec</span></span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>