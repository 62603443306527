import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';

import { ProductService } from 'src/app/sharedServices/product.service';
import { environment } from 'src/environments/environment';
import { CartService } from 'src/app/sharedServices/cart.service';
import { AuthenticationService } from 'src/app/sharedServices/authentication.service';
import { UserManagementService } from 'src/app/sharedServices/user-management.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css'],
})
export class ProductDetailComponent implements OnInit {
  private parametersObservable: any;
  product_id: any;
  cloudFrontUrl: any;
  studioWebsiteURL: any;
  categories = ['3DModel', 'Books', 'Posters', 'Coasters', 'Stickers'];

  // Sale Data
  saleData!: FormGroup;
  saleNavigation: any = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private router: Router,
    private cartService: CartService,
    public toastr: ToastrService,
    private title: Title,
    private meta: Meta,
    private authService: AuthenticationService,
    private userService: UserManagementService
  ) {
    this.cloudFrontUrl = environment.cloudFrontUrl;
    this.studioWebsiteURL = environment.studioWebsiteURL;
  }
  ngOnInit(): void {
    this.parametersObservable = this.activatedRoute.params.subscribe(
      (params) => {
        this.product_id = this.activatedRoute.snapshot.params['id'];
        this.getLoggedInuser();
        this.dataRendered = false;
        this.getProductByid(this.product_id);
      }
    );
  }

  // ============== owl-carousel start ==================
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 10,
    autoplay: true,
    navSpeed: 700,
    navText: ['&#8249', '&#8250;'],
    lazyLoad: true,
    dotsEach: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: this.saleNavigation,
  };
  // =============== owl-carousel end =================
  private isLoggedInuser!: boolean;
  userProducts: any = [];
  getLoggedInuser() {
    this.isLoggedInuser = this.authService.isLoggedIn();
    if (this.isLoggedInuser === true) {
      // console.log(this.isLoggedInuser);
      let localstorageIDs = localStorage.getItem('user-products');
      this.userProducts = localstorageIDs ? JSON.parse(localstorageIDs) : [];
    }
  }
  // ================ get Product data start ===============
  productJson: any;
  productData: any;
  dataRendered: boolean = false;
  finalDemos: any;
  categoryProducts: any = [];

  getProductByid(product_id: any) {
    this.productService.getProductByid(product_id).subscribe({
      next: (val: any) => {
        this.productJson = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.productJson.status === 1) {
          this.productData = this.productJson.product;
          if (this.productData.product_sale.length > 1) {
            this.saleNavigation = true;
          }
          this.dataRendered = true;
          this.productData.is_cart = false;
          // this.productData.loggedInuser = this.isLoggedInuser;
          this.userProducts.map((product_id: any) => {
            if (product_id === this.product_id) {
              this.productData.purchased = true;
            }
          });
          this.getproductsFromLocal();
          this.updateTitle(this.productData);
          // console.log(this.productData);
        }
      },
    });
  }

  // ===================Get product Data end ===================

  //============= Add Likes ================
  likesResponse: any;
  addLikes(product_id: any, like_type: any) {
    this.productService.addingLike(product_id, like_type).subscribe({
      next: (val: any) => {
        // console.log(val);
        this.likesResponse = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.likesResponse.status === 1) {
          if (this.likesResponse.likes?.product_likes === 1) {
            this.toastr.success('Success', this.likesResponse.message);
          } else if (this.likesResponse.likes?.product_likes === 0) {
            this.toastr.warning('Dislike', 'Sorry For your inconviniance');
          } else {
            this.toastr.warning(this.likesResponse.message);
          }
        }
      },
    });
  }
  // ============== likes end ===========

  // ======= copy url start===========
  copyClipboard() {
    let url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      this.toastr.success(
        'Share the link anywhere you like',
        'URL Copied to clipboard '
      );
    });
  }
  // ======== copy url end===========
  // ========== Rating Start ==========
  stars = [1, 2, 3, 4, 5];
  ratedCount = 0;
  updateRating(product_id: any, ratingValue: any) {
    this.ratedCount = ratingValue;
    this.addRating(product_id, this.ratedCount);
  }

  ratingResponse: any;
  addRating(product_id: any, product_rating: any) {
    this.productService.addRating(product_id, product_rating).subscribe({
      next: (val: any) => {
        // console.log(val);
        this.ratingResponse = val;
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        if (this.ratingResponse.status === 1) {
          this.toastr.success('Success', this.ratingResponse.message);
        }
      },
    });
  }
  // ========= Rating End ============

  // ======== add to cart =========
  productstoLocal: any = [];
  product_obj = {};
  addToCart(productData: any) {
    // console.log(productData);
    if (productData?.product_sale?.length === 0) {
      this.product_obj = {
        product_id: productData.product_id,
      };
    } else {
      this.product_obj = {
        product_id: productData.product_id,
        sale_id: this.sale_id,
      };
    }
    this.productstoLocal.push(this.product_obj);
    localStorage.setItem('products', JSON.stringify(this.productstoLocal));
    this.getcartItems();
    this.cartService.getCartValue(this.productstoLocal.length);
  }

  getproductsFromLocal() {
    let localCourses = localStorage.getItem('products');
    this.productstoLocal = localCourses ? JSON.parse(localCourses) : [];
    this.getcartItems();
  }

  getcartItems() {
    if (this.productstoLocal?.length === 0) {
      this.productData.is_cart = false;
    } else {
      this.productstoLocal.map((products: any) => {
        if (products.product_id === this.productData.product_id) {
          this.productData.is_cart = true;
        } else {
          this.productData.is_cart = false;
        }
      });
    }
  }

  removeCart(productData: any) {
    console.log(productData);
    let arr = this.productstoLocal.filter(
      (item: any) => item.product_id !== productData.product_id
    );
    localStorage.setItem('products', JSON.stringify(arr));
    this.getproductsFromLocal();
    this.cartService.getCartValue(this.productstoLocal.length);
  }

  sale_id: any;
  isChecked: any;
  getSale(event: any, saleData: any, index: any) {
    this.sale_id = saleData.sale_id;
    this.isChecked = event.target.checked ? index : undefined;
    console.log(this.isChecked);
  }

  //================ cart related end ====================
  // =========== MEta tags start ===================
  updateTitle(productData: any) {
    let productDescription = productData?.product_description.replace(
      /<[^>]+>/g,
      ''
    );
    this.title.setTitle(productData?.product_name);
    this.meta.updateTag({
      name: 'keywords',
      content: productData?.product_tags,
    });
    this.meta.updateTag({
      name: 'description',
      content: productDescription,
    });

    this.meta.updateTag({
      property: 'og:title',
      content: productData?.product_name,
    });
    this.meta.updateTag({
      property: 'og:type',
      content: 'webiste',
    });
    this.meta.updateTag({
      property: 'og:description',
      content: productDescription,
    });
    this.meta.updateTag({
      property: 'og:image',
      content: productData?.product_images[0],
    });
    this.meta.updateTag({
      property: 'og:url',
      content: '',
    });
    // twitter tags almost all social media platforms
    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary',
    });
    this.meta.updateTag({
      name: 'twitter:site',
      content: '@immersionslabs',
    });
    this.meta.updateTag({
      name: 'twitter:domain',
      content: 'https://shop.immersionslabs.com/home',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: productData?.product_name,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      lang: 'en',
      content: productDescription,
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: productData?.product_images[0],
    });
  }
  // =========== meta tags end ====================
  // =========== Navigation cat and publisher Start=============
  navigateToSection(type: any) {
    document.getElementById(type)?.scrollIntoView({ behavior: 'smooth' });
  }
  // =========== Navigation cat and publisher End=============
  // --------- ID Change ==================
  ngOnDestroy() {
    if (this.parametersObservable != null) {
      this.parametersObservable.unsubscribe();
    }
  }
}
