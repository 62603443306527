<!-- Collections -->
<section class="relative pb-24">
  <div class="px-6 xl:px-24">
    <h1
      class="font-display text-jacarta-700 text-4xl font-medium dark:text-white mb-8"
    >
      Explore Collections
    </h1>

    <h4
      class="font-display text-jacarta-700 text-xl font-medium dark:text-white mb-8"
    >
      Total Products:
      <span class="text-green">({{ productList?.length }})</span>
    </h4>

    <!-- Filters -->
    <!-- Caategories Filter -->
    <div class="flex flex-wrap items-center justify-between mb-4">
      <ul class="flex flex-wrap items-center">
        <li class="my-1 mr-2.5">
          <span
            class="font-display text-jacarta-700 font-semibold dark:text-white"
            >Category :
          </span>
        </li>
        <li class="my-1 mr-2.5" *ngFor="let cat of categories; let i = index">
          <a
            (click)="filterData(i, 'category', cat)"
            class="cursor-pointer dark:border-jacarta-600 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border px-4 text-sm font-medium transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white"
            [ngClass]="{
              'bg-accent text-white': i === activeCategory
            }"
          >
            <span>{{ cat }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- Publisher Filter -->
    <div class="flex flex-wrap items-center justify-between mb-8">
      <ul class="flex flex-wrap items-center">
        <li class="my-1 mr-2.5">
          <span
            class="font-display text-jacarta-700 font-semibold dark:text-white"
            >Publisher :
          </span>
        </li>
        <li
          class="my-1 mr-2.5"
          *ngFor="let publishers of publisher; let i = index"
        >
          <a
            (click)="filterData(i, 'publisher', publishers)"
            class="cursor-pointer dark:border-jacarta-600 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border px-4 text-sm font-medium transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white"
            [ngClass]="{
              'bg-accent text-white': i === activePublisher
            }"
          >
            <span>{{ publishers.publisher_name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="flex flex-wrap items-center justify-between mb-8">
      <!-- Search Bar -->
      <div class="sm\:w-full w-50">
        <form class="relative mb-4" autocomplete="off">
          <input
            type="search"
            class="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
            placeholder="Search by name or tags"
            name="search"
            [(ngModel)]="searchtext"
            (input)="changed(searchtext)"
          />
          <span
            class="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-jacarta-500 h-4 w-4 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
              />
            </svg>
          </span>
        </form>
      </div>
      <!-- Sort Filters -->
      <div class="sm\:w-full w-50">
        <div class="dropdown relative my-1 cursor-pointer">
          <div class="flex justify-end">
            <!-- Selected Filter -->
            <div
              class="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white"
              role="button"
              id="categoriesSort"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="font-display">{{ selectedSort }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="fill-jacarta-500 h-4 w-4 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
                />
              </svg>
            </div>
            <!--Filter Dropdown -->
            <div
              class="dropdown-menu dark:bg-jacarta-800 z-10 w-48 hidden whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl"
              aria-labelledby="categoriesSort"
            >
              <!-- Sale Filter -->
              <button
                class="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                (click)="sortProductByPrice('sale')"
              >
                Sale
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="fill-accent h-4 w-4"
                  *ngIf="tickIcon == 1"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                  />
                </svg>
              </button>
              <!-- Oldest on top Filter -->
              <button
                class="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                (click)="sortProductByPrice('oot')"
              >
                Oldest on top
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="fill-accent h-4 w-4"
                  *ngIf="tickIcon == 2"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                  />
                </svg>
              </button>
              <!-- Latest on top filter -->
              <button
                class="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                (click)="sortProductByPrice('lot')"
              >
                Latest on top
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="fill-accent h-4 w-4"
                  *ngIf="tickIcon == 3"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                  />
                </svg>
              </button>
              <!-- Price Low to high Filter -->
              <button
                class="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                (click)="sortProductByPrice('l2h')"
              >
                Price low to high
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="fill-accent h-4 w-4"
                  *ngIf="tickIcon == 4"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                  />
                </svg>
              </button>
              <!-- Price high to low filter -->
              <button
                class="dropdown-item font-display dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
                (click)="sortProductByPrice('h2l')"
              >
                Price high to low
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="fill-accent h-4 w-4"
                  *ngIf="tickIcon == 5"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tags -->
    <!-- <ng-container *ngIf="productTags?.length == 0;">
      <app-loader></app-loader>
      <h4></h4>
    </ng-container> -->
    <ng-container *ngIf="productTags?.length !== 0">
      <ng-container *ngIf="productTags | searchTagsFilter : searchtext as Tags">
        <h4
          class="font-display text-jacarta-700 text-xl font-medium dark:text-white text-center"
          *ngIf="Tags.length === 0"
        >
          No such Tag
        </h4>
        <div
          [ngClass]="Tags.length === 0 ? '' : 'tagsContainer'"
          class="relative mb-8 flex flex-wrap"
          id="tagContainer"
        >
          <button
            class="cursor-pointer dark:border-jacarta-600 group h-8 dark:hover:bg-accent hover:bg-accent border-jacarta-100 mr-2.5 mb-2.5 inline-flex items-center rounded-xl border px-2 py-2 hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent text-sm"
            *ngFor="let productTags of Tags; index as i"
            (click)="searchByTags(productTags, i)"
            [ngClass]="{
              'bg-accent text-white': i === activeTag
            }"
          >
            <span class="text-green">#</span>{{ productTags }}
          </button>
        </div>
      </ng-container>
    </ng-container>
    <!-- Tags end -->

    <!-- Products Grid -->
    <!-- <ng-container *ngIf="dataFetched === false; else loaderContainer">
      <app-loader></app-loader>
    </ng-container> -->
    <!-- <ng-template #loaderContainer> -->
    <ng-container *ngIf="this.productList?.length === 0; else productData">
      <h1
        class="font-display text-jacarta-700 text-xl font-medium dark:text-white text-center"
      >
        No such Product
      </h1>
    </ng-container>
    <ng-template #productData>
      <ng-container *ngIf="!dataFetched">
        <app-product-card-skelton></app-product-card-skelton>
      </ng-container>
      <ng-container
        *ngIf="
          productList
            | searchProductFilter : searchtext
            | slice : 0 : productListCount as products
        "
      >
        <h1
          *ngIf="products?.length === 0"
          class="font-display text-jacarta-700 text-xl font-medium dark:text-white text-center"
        >
          No Products Found
        </h1>

        <div
          class="grid grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-5"
        >
          <article
            *ngFor="
              let products of products
                | searchProductFilter : searchtext
                | slice : 0 : productListCount
            "
            class="cursor-pointer"
          >
            <app-product-card
              *ngIf="dataFetched"
              [products]="products"
            ></app-product-card>
          </article>
        </div>
        <ng-container *ngIf="products?.length === 0; else loadmoreContainer">
        </ng-container>
        <ng-template #loadmoreContainer>
          <div class="mt-10 text-center" *ngIf="!loadMoreButton">
            <button
              class="cursor-pointer bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              (click)="loadMoreProducts(productListCount, products)"
            >
              Load More
            </button>
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
    <!-- </ng-template> -->
    <!-- Products Grid End -->
  </div>
</section>
<!-- end collections -->
