import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card/product-card.component';
import { LoaderComponent } from './loader/loader.component';
import { ProductCardSkeltonComponent } from './shared-skeltons/product-card-skelton/product-card-skelton.component';
import { ProductDetailSkeltonComponent } from './shared-skeltons/product-detail-skelton/product-detail-skelton.component';
import { CartListSkeltonComponent } from './shared-skeltons/cart-list-skelton/cart-list-skelton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SaleListSkeltonComponent } from './shared-skeltons/sale-list-skelton/sale-list-skelton.component';
import { SaleDetailSkeltonComponent } from './shared-skeltons/sale-detail-skelton/sale-detail-skelton.component';

@NgModule({
  declarations: [
    ProductCardComponent,
    LoaderComponent,
    ProductCardSkeltonComponent,
    ProductDetailSkeltonComponent,
    CartListSkeltonComponent,
    SaleListSkeltonComponent,
    SaleDetailSkeltonComponent,
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'This item is actually loading...',
    }),
  ],
  exports: [
    ProductCardComponent,
    LoaderComponent,
    ProductCardSkeltonComponent,
    ProductDetailSkeltonComponent,
    CartListSkeltonComponent,
    SaleListSkeltonComponent,
    SaleDetailSkeltonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
