<div
  class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
  (click)="navigateToDetail(products)"
>
  <figure class="group relative">
    <ng-container
      *ngIf="products.hasOwnProperty('product_data'); else imageContainer"
    >
      <img
        [src]="cloudFrontURL + products.product_data?.product_images['0']"
        [alt]="products.product_name"
        class="h-full w-full rounded-[0.625rem] object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
        loading="lazy"
      />
    </ng-container>
    <ng-template #imageContainer>
      <img
        [src]="cloudFrontURL + products?.product_images['0']"
        [alt]="products.product_name"
        class="h-full w-full rounded-[0.625rem] object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
        loading="lazy"
      />
    </ng-template>
    <ng-conatiner *ngIf="products.hasOwnProperty('sale_id')">
      <div
        class="dark:bg-jacarta-700 absolute top-0 right-3 flex items-center space-x-1 rounded-md bg-white p-2"
        *ngIf="products.product_sale?.length !== 0"
      >
        <span class="dark:text-jacarta-200 text-sm"
          >Purchased on {{ products.sale_title }}</span
        >
      </div>
    </ng-conatiner>
    <!-- <ng-template #saleActive>
      <div class="dark:bg-jacarta-700 absolute top-0 right-3 flex items-center space-x-1 rounded-md bg-white p-2"
        *ngIf="products.product_sale?.length !== 0">
        <span class="dark:text-jacarta-200 text-sm">On Sale</span>
      </div>
    </ng-template> -->
  </figure>

  <a
    class="text-ellipsis-2 font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white"
  >
    {{ products.product_name }}
  </a>

  <div
    class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight"
  >
    <div class="flex flex-wrap items-center">
      <ng-container
        *ngIf="
          products.hasOwnProperty('product_final_price');
          else normalPriceContainer
        "
      >
        <span class="mr-2 text-accent"
          >₹ {{ products.product_final_price }}</span
        >
      </ng-container>
      <ng-template #normalPriceContainer>
        <span class="mr-2 text-accent"
          >₹
          {{
            products.product_price -
              (products.product_price * products.product_individual_discount) /
                100
          }}</span
        >
      </ng-template>

      <span class="text-sm mr-2"
        ><del>₹{{ products.product_price }}</del></span
      >
      <ng-container
        *ngIf="products.hasOwnProperty('sale_discount'); else saleDiscount"
      >
        <span class="text-sm dark:text-white"
          >{{ products.product_individual_discount + products.sale_discount }} %
          Off</span
        >
      </ng-container>
      <ng-template #saleDiscount>
        <span class="text-sm dark:text-white"
          >{{ products.product_individual_discount }} % Off</span
        >
      </ng-template>
    </div>
  </div>
  <div class="flex flex-wrap justify-between items-center mt-2">
    <span class="dark:text-jacarta-400 flex flex-wrap items-center mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-eye mr-1"
        viewBox="0 0 16 16"
      >
        <path
          d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"
        />
        <path
          d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
        />
      </svg>
      {{ products.product_views }}
    </span>

    <span class="text-sm flex flex-wrap items-center mr-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4 mr-1"
      >
        <path fill="none" d="M0 0H24V24H0z"></path>
        <path
          d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z"
        ></path>
      </svg>
      {{ products.product_likes }}
    </span>
    <span class="text-sm flex flex-wrap items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-star-fill mr-1 text-yellow"
        viewBox="0 0 16 16"
      >
        <path
          d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
        />
      </svg>
      <ng-container
        *ngIf="
          products.product_rating === 0 || products.product_rating === null;
          else ratingContainer
        "
      >
        0
      </ng-container>
      <ng-template #ratingContainer>
        {{ products.product_rating }}
      </ng-template>
    </span>
  </div>
</div>
