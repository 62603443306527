import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserManagementService {
  apiKey: any;
  constructor(private httpClient: HttpClient) {
    this.apiKey = environment.apiUrl;
  }

  getHeaders() {
    var a_token = localStorage.getItem('access_token');
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + a_token,
      },
    };
    return options;
  }
  getLoggedinUser() {
    const options = this.getHeaders();
    return this.httpClient.get(this.apiKey + 'user/', options);
  }

  updateUserData(payload: any) {
    const options = this.getHeaders();
    return this.httpClient.patch(
      this.apiKey + 'user/profile',
      payload,
      options
    );
  }

  userChangePassword(payload: any) {
    const options = this.getHeaders();
    return this.httpClient.patch(
      this.apiKey + 'user/password',
      payload,
      options
    );
  }
}
