import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
// @ts-ignore
import crypto from 'crypto-browserify';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/sharedServices/user-management.service';
import { ToastrService } from 'ngx-toastr';
declare var Stripe: any;

import * as StripePackage from 'stripe';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-payment-checkout',
  templateUrl: './payment-checkout.component.html',
  styleUrls: ['./payment-checkout.component.css'],
})
export class PaymentCheckoutComponent implements OnInit {
  iv: any;
  key: any;
  Algorithm: any;
  client_secret: any;
  loader_status!: boolean;

  // ============ Constructor  Start =======================
  constructor(
    private router: Router,
    private userService: UserManagementService,
    private toatsr: ToastrService,
    private el: ElementRef,
    private title: Title
  ) {
    this.iv = environment.iv;
    this.key = environment.key;
    this.Algorithm = environment.Algorithm;

    let biv = Buffer.from(this.iv, 'hex');
    let bkey = Buffer.from(this.key, 'hex');
    let client_secret_hash = localStorage.getItem('sc');
    const decipher = crypto.createDecipheriv(this.Algorithm, bkey, biv);
    this.client_secret = decipher.update(client_secret_hash, 'hex', 'utf8');
    this.client_secret += decipher.final('utf8');

    this.retrieveintent(this.client_secret);
    // document.getElementsByTagName('body')[0].classList.add('color-light');
    // document
    //   .getElementsByTagName('body')[0]
    //   .classList.remove('dark:bg-jacarta-900');
  }
  // ============ Constructor  End =======================

  // ============ NgOinit Start =======================
  ngOnInit(): void {
    this.userData();
    // Payment Form
    const stripe = Stripe(environment.stripepublish_key);
    // Card Creation

    const elements = stripe.elements();

    // Remove Zip-code in card UI component
    var card = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fff',
            backgroundColor: '#fff',
          },
        },
        invalid: {
          iconColor: '#dc3545',
          color: '#dc3545',
        },
      },
    });
    card.mount('#card-element');
    card!.addEventListener('change', (event: any) => {
      const displayError = document.getElementById('card-errors')!;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    const country = document.getElementById('country')!;
    const city = document.getElementById('city')!;
    const state = document.getElementById('state')!;
    const line1 = document.getElementById('addressLine')!;
    country.addEventListener('change', (event: any) => {
      const displayError = document.getElementById('country-errors')!;
      displayError.textContent = '';
    });
    city.addEventListener('change', (event: any) => {
      const displaycityError = document.getElementById('city-errors')!;
      displaycityError.textContent = '';
    });
    state.addEventListener('change', (event: any) => {
      const displaystateError = document.getElementById('state-errors')!;
      displaystateError.textContent = '';
    });
    line1.addEventListener('change', (event: any) => {
      const displayline1Error = document.getElementById('line1-errors')!;
      displayline1Error.textContent = '';
    });
    const paymentForm = document.getElementById('payment-form')!;
    paymentForm.addEventListener('submit', (event) => {
      event.preventDefault();
      let country = (document.getElementById('country') as HTMLInputElement)
        .value;
      let state = (document.getElementById('state') as HTMLInputElement).value;
      let city = (document.getElementById('city') as HTMLInputElement).value;
      let Line1 = (document.getElementById('addressLine') as HTMLInputElement)
        .value;
      localStorage.removeItem('sc');
      if (country && state && city && Line1) {
        this.loader_status = true;
        stripe
          .confirmCardPayment(this.client_secret, {
            payment_method: {
              card: card,
              billing_details: {
                name:
                  this.user_data.user_first_name +
                  ' ' +
                  this.user_data.user_last_name,
                address: {
                  city: city,
                  line1: Line1,
                  state: state,
                  country: country,
                },
              },
            },
          })
          .then((result: any) => {
            if (result.error) {
              // CARD DECLINE OR AUTHENTICATION FAILURE OR FAILED PAYMENT
              this.toatsr.error(result.error.message);
              this.loader_status = false;
            } else if (result.paymentIntent) {
              let status = result.paymentIntent;
              if (status && status.status === 'succeeded') {
                this.toatsr.success('Payment Successfull');
                this.router.navigate(['/payment-status']);
                this.loader_status = false;
              } else if (status && status.status === 'requires_action') {
                this.toatsr.error('Payment Failed');
                this.router.navigate(['/cart']);
                this.loader_status = false;
              } else if (
                status &&
                status.status === 'requires_payment_method'
              ) {
                this.toatsr.error('Payment Incomplete');
                this.router.navigate(['/cart']);
                this.loader_status = false;
              } else {
                this.toatsr.warning('Something went wrong');
                this.router.navigate(['/home']);
                this.loader_status = false;
              }
            }
          });
      } else {
        const displayError = document.getElementById('country-errors')!;
        displayError.textContent = 'Please choose a country';
        const displaycityError = document.getElementById('city-errors')!;
        displaycityError.textContent = 'Please provide your city';
        const displaystateError = document.getElementById('state-errors')!;
        displaystateError.textContent = 'Please provide your state';
        const displayline1Error = document.getElementById('line1-errors')!;
        displayline1Error.textContent = 'Please provide a valid address';
      }
    });
  }
  // ============ NgOinit ENd =======================
  // ============ Retrieve Intent start =======================
  amount: any;
  products: any = [];
  loaderContent: boolean = false;
  productParams: any;
  pp: string = '\n';
  gstValue: any;
  retrieveintent(client_secret: any) {
    // console.log(client_secret);
    let secret = client_secret.split('_secret_');
    // console.log(secret);
    const stripe = new StripePackage.Stripe(environment.stripeSecret_key);
    stripe.paymentIntents.retrieve(secret[0]).then((result: any) => {
      console.log(result);

      if (result.error) {
        this.router.navigate(['/home']);
      } else if (result.amount) {
        this.loaderContent = true;
        this.amount = result.amount;
        if (result.metadata.ppblocks) {
          for (var i = 0; i < result.metadata.ppblocks; i++) {
            this.pp += result.metadata['pp' + i];
          }
        }
        this.products = JSON.parse(this.pp);
        // console.log(this.products);
        let cost = 0;
        this.products.map((products: any) => {
          cost += products.product_final_price;
        });
        this.gstValue = (cost * 12) / 100;
        // console.log(this.gstValue);
        if (result.status === 'succeeded') {
          this.router.navigate(['/profile']);
        }
      }
    });
  }
  // ============ Retrieve Intent End =======================
  // ============ user Form Start =======================
  userjson: any;
  error_user_data: any;
  user_data: any;
  userData() {
    this.userService.getLoggedinUser().subscribe({
      next: (val) => {
        this.userjson = val;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        if (this.userjson.status == 1) {
          this.user_data = this.userjson.user;
        } else {
          console.log(this.userjson.message);
          // this.toastr.error(this.userjson.message);
        }
      },
    });
  }
  // ============ user Form End =======================
  // =========== MEta tags start ===================
  updateTitle() {
    this.title.setTitle('Stripe Checkout | Shopforar');
  }
  // =========== meta tags end ====================
}
