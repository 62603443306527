import { Component } from '@angular/core';

@Component({
  selector: 'app-razor-pay',
  templateUrl: './razor-pay.component.html',
  styles: [
  ]
})
export class RazorPayComponent {

}
