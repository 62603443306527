import { Component, Input, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/sharedServices/authentication.service';

@Component({
  selector: 'app-product-detail-tabs',
  templateUrl: './product-detail-tabs.component.html',
})
export class ProductDetailTabsComponent implements OnInit {
  @Input() productDataTabs: any;
  categories = ['3DModel', 'Books', 'Posters', 'Coasters', 'Stickers'];
  constructor(private authService: AuthenticationService) { }
  ngOnInit(): void {
    // console.log(this.productDataTabs);
  }
  // =========== Navigation cat and publisher Start=============
  navigateToSection(type: any) {
    document.getElementById(type)?.scrollIntoView({ behavior: 'smooth' });
  }
  // =========== Navigation cat and publisher End=============

  loggedin!: boolean;
  isAuthenticated() {
    return this.authService.isLoggedIn();
    // if (this.isAuthenticated) {
    //   this.getProfileData();
    // }
  }
}
