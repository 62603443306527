import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchTagsFilter',
})
export class SearchTagsFilterPipe implements PipeTransform {
  transform(items: any, searchtext: string): any[] {
    if (!items) return [];
    if (!searchtext) return items;
    return items.filter((item: any) => {
      return item.includes(searchtext);
    });
  }
}
