import { Component } from '@angular/core';

@Component({
  selector: 'app-cart-list-skelton',
  templateUrl: './cart-list-skelton.component.html',
  styles: [],
})
export class CartListSkeltonComponent {
  cardList = [1, 2, 3];
  themeColor: any;
  constructor() {
    if (localStorage.getItem('theme-preference') === 'dark') {
      this.themeColor = '#131740';
    } else if (localStorage.getItem('theme-preference') === 'light') {
      this.themeColor = '#e7e8ec';
    }
  }
}
