<section class="relative py-24 dark:bg-jacarta-700">
  <div class="px-6 xl:px-24">
    <div class="relative">
      <ng-container *ngIf="dataRendered === false; else dataContainer">
        <div class="mt-16">
          <app-cart-list-skelton></app-cart-list-skelton>
        </div>
      </ng-container>
      <ng-template #dataContainer>
        <h1 class="font-display text-jacarta-700 py-16 text-4xl font-medium dark:text-white">
          Purchased Products
        </h1>
        <!-- Records / Filter -->
        <div class="lg:flex">
          <!-- Records -->
          <div class="mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pr-10">
            <ng-container *ngIf="onlineProducts.length > 0">
              <h4 class="font-display text-jacarta-700 py-3 text-xl font-medium dark:text-white">
                Selected Online Products
              </h4>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 relative flex flex-wrap items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg relative"
                *ngFor="let online of onlineProducts">
                <figure class="mr-5 self-start">
                  <img [src]="cloudFrontURL + online.product_images[0]" [alt]="online.product_name"
                    class="rounded-2lg h-40" loading="lazy" />
                </figure>

                <div class="mr-5">
                  <!-- (click)="navigateToDetail(online)" -->
                  <a class="cursor-pointer">
                    <h3 class="font-display text-jacarta-700 mb-4 text-lg font-semibold dark:text-white">
                      {{ online.product_name }}
                    </h3>
                  </a>
                  <div
                    class="mb-4 dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg cursor-pointer flex items-center"
                    [ngClass]="{
                      'bg-accent text-white': online.regular === true
                    }">
                    <div class="mr-2" *ngIf="online.regular === true">
                      <span
                        class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                          class="h-[.875rem] w-[.875rem] fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <span class="text-sm mr-2 dark:text-white font-semibold">Regular Discount Price
                      </span>
                      <div class="flex flex-wrap items-center">
                        <span class="mr-2 text-green">₹{{
                          online.product_price - (online.product_price * online.product_individual_discount) / 100
                          }}
                        </span>
                        <span class="text-sm mr-2 dark:text-white"><del>₹{{ online.product_price }}</del></span>
                        <span class="text-sm text-red">{{ online.product_individual_discount }} % Off</span>
                      </div>
                    </div>
                  </div>
                  <div class="items-center" *ngIf="online?.prodcut_sale_data !== 0">
                    <ng-container *ngFor="
                        let salelist of online?.prodcut_sale_data;
                        let i = index
                      ">
                      <div
                        class="mb-4 flex dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg items-center cursor-pointer"
                        [ngClass]="{'bg-accent text-white': salelist.selected === true}">
                        <div class="mr-2" *ngIf="salelist.selected === true">
                          <span
                            class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                              class="h-[.875rem] w-[.875rem] fill-white">
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                          </span>
                        </div>
                        <div>
                          <span class="text-sm mr-2 dark:text-white font-semibold">{{ salelist.sale_title }}
                          </span>
                          <div class="flex flex-wrap items-center">
                            <span class="mr-2 text-green">₹{{
                              online.product_price - (online.product_price * (online.product_individual_discount +
                              salelist.sale_discount)) / 100
                              }}
                            </span>
                            <span class="text-sm mr-2 dark:text-white"><del>₹{{ online.product_price }}</del></span>
                            <span class="text-sm text-red">
                              ({{ online.product_individual_discount }} + {{ salelist.sale_discount }})% Discount
                            </span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="offlineProducts.length > 0">
              <h4 class="font-display text-jacarta-700 py-3 text-xl font-medium dark:text-white">
                Selected Offline Products
              </h4>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 relative flex items-center rounded-[1.25rem] border bg-white p-4 transition-shadow hover:shadow-lg"
                *ngFor="let offline of offlineProducts">
                <figure class="mr-5 self-start">
                  <img [src]="cloudFrontURL + offline.product_images[0]" [alt]="offline.product_name"
                    class="rounded-2lg h-40" loading="lazy" />
                </figure>
                <div>
                  <!-- (click)="navigateToDetail(offline)" -->
                  <a class="cursor-pointer">
                    <h3 class="font-display text-jacarta-700 mb-1 text-lg font-semibold dark:text-white">
                      {{ offline.product_name }}
                    </h3>
                  </a>
                  <div class="mr-5">
                    <div
                      class="mb-4 dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg cursor-pointer flex items-center"
                      [ngClass]="{
                        'bg-accent text-white': offline.regular === true
                      }">
                      <div class="mr-2" *ngIf="offline.regular === true">
                        <span
                          class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                            class="h-[.875rem] w-[.875rem] fill-white">
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                          </svg>
                        </span>
                      </div>
                      <div>
                        <span class="text-sm mr-2 dark:text-white font-semibold">Regular Discount Price
                        </span>
                        <div class="flex flex-wrap items-center">
                          <span class="mr-2 text-green">₹{{
                            offline.product_price - (offline.product_price * offline.product_individual_discount) / 100
                            }}
                          </span>
                          <span class="text-sm mr-2 dark:text-white"><del>₹{{ offline.product_price }}</del></span>
                          <span class="text-sm text-red">{{ offline.product_individual_discount }} % Off</span>
                        </div>
                      </div>
                    </div>
                    <div class="items-center" *ngIf="offline?.prodcut_sale_data !== 0">
                      <ng-container *ngFor="
                          let salelist of offline?.prodcut_sale_data;
                          let i = index
                        ">
                        <div
                          class="mb-4 flex dark:border-jacarta-600 dark:bg-transparent rounded-2lg border-jacarta-100 border p-2 transition-shadow hover:shadow-lg items-center cursor-pointer"
                          [ngClass]="{
                            'bg-accent text-white': salelist.selected === true
                          }">
                          <div class="mr-2" *ngIf="salelist.selected === true">
                            <span
                              class="dark:border-jacarta-600 flex bg-green h-6 w-6 items-center justify-center rounded-full border-2 border-white mr-2">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                class="h-[.875rem] w-[.875rem] fill-white">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <span class="text-sm mr-2 dark:text-white font-semibold">{{ salelist.sale_title }}
                            </span>
                            <div class="flex flex-wrap items-center">
                              <span class="mr-2 text-green">₹{{
                                offline.product_price - (offline.product_price * (offline.product_individual_discount +
                                salelist.sale_discount)) / 100
                                }}
                              </span>
                              <span class="text-sm mr-2 dark:text-white"><del>₹{{ offline.product_price }}</del></span>
                              <span class="text-sm text-red">
                                ({{ offline.product_individual_discount }} + {{ salelist.sale_discount }})% Discount
                              </span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- Filters -->
          <aside class="basis-4/12 lg:pl-5">
            <div
              class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 relative flex items-center rounded-[1.25rem] border bg-white p-2 transition-shadow hover:shadow-lg mb-8">
              <div class="mx-auto text-center py-16">
                <img src="assets/img/success.gif" alt="" class="rounded-2lg" loading="lazy" />
                <h1 class="font-display text-jacarta-700 py-3 text-center text-4xl font-medium dark:text-white">
                  Payment Successful
                </h1>
                <div class="flex space-x-4">
                  <a routerLink="/home"
                    class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-2 px-4 text-center font-semibold text-white transition-all">
                    Continue to Shop
                  </a>
                  <a routerLink="/profile"
                    class="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded-full bg-white py-2 px-8 text-center font-semibold transition-all hover:text-white">
                    Profile
                  </a>
                </div>
              </div>
            </div>
            <h4 class="font-display text-jacarta-700 py-3 text-xl font-medium dark:text-white">
              Payment Information
            </h4>
            <div
              class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <ng-container *ngIf="onlineProducts.length > 0">
                <h6 class="font-display text-jacarta-700 py-3 text-lg font-medium dark:text-white">
                  Online Products Cost
                </h6>
                <div class="mb-4 flex items-center justify-between text-sm font-medium tracking-tight"
                  *ngFor="let onlineP of onlineProducts">
                  <div class="items-center">
                    <span class="mr-2 text-md dark:text-jacarta-300">{{ onlineP.product_name }}
                    </span>
                  </div>
                  <div class="items-center">
                    <ng-container *ngIf="
                        onlineP.hasOwnProperty('selected_sale_discount');
                        else regularPriceContainer
                      ">
                      <span class="mr-2 text-md text-green">₹{{
                        onlineP.product_price - (onlineP.product_price * (onlineP.product_individual_discount +
                        onlineP.selected_sale_discount)) / 100
                        }}
                      </span>
                    </ng-container>
                    <ng-template #regularPriceContainer>
                      <span class="mr-2 text-md text-green">₹{{
                        onlineP.product_price - (onlineP.product_price * onlineP.product_individual_discount) / 100
                        }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="offlineProducts.length > 0">
                <h6 class="font-display text-jacarta-700 py-3 text-lg font-medium dark:text-white">
                  Offline Products Cost
                </h6>
                <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight"
                  *ngFor="let offlineP of offlineProducts">
                  <div class="items-center">
                    <span class="mr-2 text-md dark:text-jacarta-300">{{ offlineP.product_name }}
                    </span>
                  </div>
                  <div class="items-center">
                    <ng-container *ngIf="
                        offlineP.hasOwnProperty('selected_sale_discount');
                        else regularPriceContainer
                      ">
                      <span class="mr-2 text-md text-green">₹{{
                        offlineP.product_price - (offlineP.product_price * (offlineP.product_individual_discount +
                        offlineP.selected_sale_discount)) / 100
                        }}
                      </span>
                    </ng-container>
                    <ng-template #regularPriceContainer>
                      <span class="mr-2 text-md text-green">₹{{
                        offlineP.product_price - (offlineP.product_price * offlineP.product_individual_discount) / 100
                        }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                <div class="items-center">
                  <span class="mr-2 text-md dark:text-jacarta-300">GST </span>
                </div>
                <div class="items-center">
                  <span class="mr-2 text-md text-green">₹{{ ((totalValue * 12) / 100).toFixed(2) }}</span>
                </div>
              </div>
              <hr class="mt-6 mb-6" />
              <div class="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">
                <div class="items-center">
                  <span class="mr-2 text-lg dark:text-white">Total: </span>
                </div>
                <div class="items-center">
                  <span class="mr-2 text-lg text-green">₹{{
                    (totalValue + (totalValue * 12) / 100).toFixed(2)
                    }}
                  </span>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </ng-template>
    </div>
  </div>
</section>